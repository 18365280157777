"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Help = void 0;
const Panel_1 = require("./Panel");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const scrollViewer_1 = require("@babylonjs/gui/2D/controls/scrollViewers/scrollViewer");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
class Panel_Help extends Panel_1.Panel {
    constructor(_UI, _currentPlayer, options) {
        super(_UI, _currentPlayer, options);
        this.helpData = this._game.loadGameData("help");
        this.createContent();
    }
    // create panel
    createContent() {
        return __awaiter(this, void 0, void 0, function* () {
            // add scrollable container
            const chatScrollViewer = new scrollViewer_1.ScrollViewer("scrollViewer");
            chatScrollViewer.width = 1;
            chatScrollViewer.height = 1;
            chatScrollViewer.top = 0;
            chatScrollViewer.thickness = 1;
            chatScrollViewer.color = "rgba(255,255,255,.5)";
            chatScrollViewer.setPaddingInPixels(10, 10, 10, 10);
            chatScrollViewer.fontSizeInPixels = 16;
            chatScrollViewer.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            chatScrollViewer.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            this._panelContent.addControl(chatScrollViewer);
            // add stack panel
            const chatStackPanel = new stackPanel_1.StackPanel("stackPanel");
            chatStackPanel.width = 1;
            chatStackPanel.adaptHeightToChildren = true;
            chatStackPanel.setPaddingInPixels(10, 10, 10, 10);
            chatStackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            chatStackPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            chatScrollViewer.addControl(chatStackPanel);
            this._stackPanel = chatStackPanel;
            // add content
            for (let tabId in this.helpData) {
                const tab = this.helpData[tabId];
                tab.objects.forEach((object) => {
                    // title
                    const titleText = new textBlock_1.TextBlock("title", object.title);
                    titleText.width = 1;
                    titleText.color = this._game.config.UI_PRIMARY_COLOR;
                    titleText.resizeToFit = true;
                    titleText.fontWeight = "bold";
                    titleText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                    titleText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
                    titleText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                    chatStackPanel.addControl(titleText);
                    // description
                    const descrText = new textBlock_1.TextBlock("description", object.description + "\n");
                    descrText.width = 1;
                    descrText.color = "white";
                    descrText.resizeToFit = true;
                    descrText.textWrapping = 1 /* TextWrapping.WordWrap */;
                    descrText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                    descrText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
                    descrText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                    chatStackPanel.addControl(descrText);
                });
            }
        });
    }
}
exports.Panel_Help = Panel_Help;
