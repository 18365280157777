"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createButton = exports.applyTheme = exports.generatePanel = exports.getPadding = exports.getBg = exports.applyFont = void 0;
const Theme1_1 = require("./Theme1");
Object.defineProperty(exports, "generatePanel", { enumerable: true, get: function () { return Theme1_1.generatePanel; } });
Object.defineProperty(exports, "applyFont", { enumerable: true, get: function () { return Theme1_1.applyFont; } });
Object.defineProperty(exports, "getBg", { enumerable: true, get: function () { return Theme1_1.getBg; } });
Object.defineProperty(exports, "getPadding", { enumerable: true, get: function () { return Theme1_1.getPadding; } });
Object.defineProperty(exports, "applyTheme", { enumerable: true, get: function () { return Theme1_1.applyTheme; } });
Object.defineProperty(exports, "createButton", { enumerable: true, get: function () { return Theme1_1.createButton; } });
