"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamageText = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const Utils_1 = require("../../../shared/Utils");
class DamageText {
    constructor(ui, scene, entities) {
        this.damageText = [];
        this._ui = ui;
        this._scene = scene;
        // some ui must be constantly refreshed as things change
        this._scene.registerBeforeRender(() => {
            // refresh
            this._update();
        });
    }
    addDamage(entity, amount) {
        let color = amount < 0 ? "red" : "yellowgreen";
        let amountText = amount > 0 ? "+" + amount : "" + amount;
        let key = this.damageText.length;
        var rect1 = new rectangle_1.Rectangle("damage_" + key + "_" + entity.sessionId);
        rect1.isVisible = true;
        rect1.width = "50px";
        rect1.height = "40px";
        rect1.thickness = 0;
        rect1.zIndex = this._ui.addControl(rect1);
        rect1.linkWithMesh(entity.mesh);
        rect1.linkOffsetY = -50;
        rect1.metadata = { offset: (0, Utils_1.randomNumberInRange)(-0.5, 0.5) };
        var label = new textBlock_1.TextBlock("text_" + entity.sessionId);
        label.text = amountText;
        label.color = color;
        label.fontWeight = "bold";
        label.fontSize = "22px";
        label.outlineWidth = 3;
        label.outlineColor = "black";
        rect1.addControl(label);
        this.damageText.push(rect1);
    }
    _update() {
        let alphaIncrement = 0.05;
        this.damageText.forEach((v, k) => {
            v.linkOffsetYInPixels -= 2;
            v.linkOffsetXInPixels -= v.metadata.offset;
            if (v.linkOffsetYInPixels < -130 && v.alpha >= alphaIncrement) {
                v.alpha -= alphaIncrement;
            }
            if (v.linkOffsetYInPixels < -250) {
                v.dispose();
                delete this.damageText[k];
            }
        });
    }
}
exports.DamageText = DamageText;
