"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const Theme_1 = require("./Theme");
const types_1 = require("../../../shared/types");
const Rarity_1 = require("../../../shared/Class/Rarity");
class Tooltip {
    constructor(_UI, _currentPlayer) {
        this.horizontal = "center";
        this.vertical = "top";
        this._game = _UI._game;
        this._playerUI = _UI._playerUI;
        this._loadedAssets = _UI._loadedAssets;
        this._engine = _UI._engine;
        this._scene = _UI._scene;
        this._currentPlayer = _currentPlayer;
        // create basic tooltip ui
        this._createUI();
    }
    _createUI() {
        const tooltipBar = (0, Theme_1.generatePanel)("tooltipBar", "200px;", "200px", "0px", "0px");
        tooltipBar.background = "#222222";
        tooltipBar.isVisible = false;
        tooltipBar.adaptHeightToChildren = true;
        tooltipBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipBar.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipBar.zIndex = 50;
        this._playerUI.addControl(tooltipBar);
        this.tooltipContainer = tooltipBar;
        const tooltipBarStack = new stackPanel_1.StackPanel("tooltipBarStack");
        tooltipBarStack.width = 1;
        tooltipBarStack.setPaddingInPixels(5, 5, 5, 5);
        tooltipBar.addControl(tooltipBarStack);
        let headerHeight = "35px";
        let tooltipHeader = new rectangle_1.Rectangle("tooltipHeader");
        tooltipHeader.thickness = 0;
        tooltipHeader.height = headerHeight;
        tooltipHeader.paddingBottom = "5px";
        tooltipBarStack.addControl(tooltipHeader);
        const tooltipImage = new rectangle_1.Rectangle("tooltipImage");
        tooltipImage.top = "2px";
        tooltipImage.left = "0x";
        tooltipImage.width = "25px";
        tooltipImage.height = "25px";
        tooltipImage.thickness = 0;
        tooltipImage.background = this._game.config.UI_CENTER_PANEL_BG;
        tooltipImage.isVisible = true;
        tooltipImage.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipImage.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipHeader.addControl(tooltipImage);
        this.tooltipImage = tooltipImage;
        // add name
        const tooltipName = new textBlock_1.TextBlock("tooltipName");
        tooltipName.width = 0.8;
        tooltipName.color = "#FFF";
        tooltipName.top = "4px";
        tooltipName.left = "30px";
        tooltipName.fontSize = "16px;";
        tooltipName.resizeToFit = true;
        tooltipName.text = "Item Name";
        tooltipName.fontWeight = "bold";
        tooltipName.lineSpacing = "-2";
        tooltipName.textWrapping = 3 /* TextWrapping.WordWrapEllipsis */;
        tooltipName.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipName.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipName.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipName.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipHeader.addControl(tooltipName);
        this.tooltipName = tooltipName;
        //
        const tooltipStats = new textBlock_1.TextBlock("tooltipStats");
        tooltipStats.color = "green";
        tooltipStats.top = "0px";
        tooltipStats.left = "0px";
        tooltipStats.fontSize = "14px;";
        tooltipStats.resizeToFit = true;
        tooltipStats.fontWeight = "bold";
        tooltipStats.text = "";
        tooltipStats.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipStats.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipStats.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipStats.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipBarStack.addControl(tooltipStats);
        this.tooltipStats = tooltipStats;
        // add description
        const tooltipDescription = new textBlock_1.TextBlock("tooltipDescription");
        tooltipDescription.color = "#FFF";
        tooltipDescription.top = "0px";
        tooltipDescription.left = "0px";
        tooltipDescription.width = 1;
        tooltipDescription.fontSize = "14px;";
        tooltipDescription.resizeToFit = true;
        tooltipDescription.text = "";
        tooltipDescription.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipDescription.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipDescription.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipDescription.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipDescription.textWrapping = 1 /* TextWrapping.WordWrap */;
        tooltipDescription.paddingBottom = "5px";
        tooltipBarStack.addControl(tooltipDescription);
        this.tooltipDescription = tooltipDescription;
        // add value
        const tooltipValue = new textBlock_1.TextBlock("tooltipValue");
        tooltipValue.color = "gray";
        tooltipValue.top = "0px";
        tooltipValue.left = "0px";
        tooltipValue.width = 1;
        tooltipValue.fontSize = "12px;";
        tooltipValue.resizeToFit = true;
        tooltipValue.text = "";
        tooltipValue.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipValue.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        tooltipValue.textWrapping = 1 /* TextWrapping.WordWrap */;
        tooltipBarStack.addControl(tooltipValue);
        this.tooltipValue = tooltipValue;
    }
    generateItem(data) {
        this.tooltipName.text = data.title;
        this.tooltipDescription.text = data.description;
        this.tooltipValue.isVisible = true;
        this.tooltipValue.text = "Value: " + data.value;
        //
        let stats = "";
        for (let key in data.statModifiers) {
            for (let line of data.statModifiers[key]) {
                let title = key.toUpperCase() + ": ";
                if (line.type === types_1.CalculationTypes.ADD) {
                    stats += title + " + " + line.value + "\n";
                }
                else if (line.type === types_1.CalculationTypes.MULTIPLY) {
                    stats += title + " + " + line.value * 10 + "% \n";
                }
            }
        }
        // if damage
        if (data.damage) {
            stats += "Damage: " + data.damage.min + " - " + data.damage.max + " \n";
        }
        stats = stats.slice(0, -1);
        this.tooltipStats.text = stats;
        this.tooltipStats.isVisible = stats === "" ? false : true;
        // color based on rarity
        this.tooltipContainer.color = Rarity_1.Rarity.getColor(data).color;
        this.tooltipName.color = Rarity_1.Rarity.getTooltipColor(data, 1);
    }
    generateAbility(data) {
        this.tooltipName.text = data.title;
        this.tooltipDescription.text = data.description;
        // hide value
        this.tooltipValue.text = "0";
        this.tooltipValue.isVisible = false;
        // generate ability stats
        let stats = "";
        if (data.casterPropertyAffected.length > 0) {
            data.casterPropertyAffected.forEach((element) => {
                stats += "Cost: " + element.min + "-" + element.max + " " + element.key.toUpperCase() + "\n";
            });
        }
        if (data.cooldown > 0) {
            stats += "Cooldown: " + data.cooldown / 1000 + "s\n";
        }
        if (data.castTime > 0) {
            stats += "Cast time: " + data.castTime / 1000 + "s\n";
        }
        else {
            stats += "Instant Cast\n";
        }
        stats = stats.slice(0, -1);
        this.tooltipStats.text = stats;
        // color based on rarity
        this.tooltipContainer.color = Rarity_1.Rarity.getColor(false).color;
        this.tooltipName.color = Rarity_1.Rarity.getTooltipColor(false, 1);
    }
    /** called externally to refresh tooltip with content */
    refresh(type, data, el, horizontal = "left", vertical = "center") {
        // set tooltip target
        this.tooltipTarget = el;
        // position tooltip
        this.horizontal = horizontal;
        this.vertical = vertical;
        this.setPosition();
        // remove image
        this.tooltipImage.children.forEach((element) => {
            element.dispose();
        });
        // add image
        var imageData = this._loadedAssets[data.icon];
        var img = new image_1.Image("tooltipImageImg", imageData);
        img.stretch = image_1.Image.STRETCH_FILL;
        this.tooltipImage.addControl(img);
        switch (type) {
            case "item":
                this.generateItem(data);
                break;
            case "ability":
                this.generateAbility(data);
                break;
        }
        // show tooltip
        this.tooltipContainer.isVisible = true;
    }
    /** called externally to hide tooltip */
    close() {
        this.tooltipContainer.isVisible = false;
        this.tooltipTarget = null;
    }
    setPosition() {
        if (this.tooltipTarget) {
            let tooltipHeight = this.tooltipContainer.heightInPixels;
            let tooltipWidth = this.tooltipContainer.widthInPixels;
            let targetHeight = this.tooltipTarget.heightInPixels;
            let targetWidth = this.tooltipTarget.widthInPixels;
            let x = this.tooltipTarget.centerX;
            let y = this.tooltipTarget.centerY;
            if (this.horizontal === "left") {
                x -= targetWidth / 2 + tooltipWidth;
            }
            if (this.horizontal === "center") {
                x -= tooltipWidth / 2;
            }
            if (this.horizontal === "right") {
                x += targetWidth / 2;
            }
            if (this.vertical === "top") {
                y -= targetHeight / 2 + tooltipHeight;
            }
            if (this.vertical === "center") {
                y -= tooltipHeight / 2;
            }
            if (this.vertical === "bottom") {
                y += targetHeight / 2;
            }
            this.tooltipContainer.leftInPixels = x;
            this.tooltipContainer.topInPixels = y;
        }
    }
    update() {
        this.setPosition();
    }
}
exports.Tooltip = Tooltip;
