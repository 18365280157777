"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityAnimator = void 0;
const types_1 = require("../../../shared/types");
class EntityAnimator {
    constructor(entity) {
        //animations
        this._animations = [];
        //
        this.currentFrame = 0;
        this.targetFrame = 0;
        this.endOfLoop = false;
        this.currentAnimationRange = 0;
        this.currentAnimationIndex = 0;
        // get player mesh
        this.mesh = entity.meshController.mesh;
        this.entityData = entity.entityData;
        this._entity = entity;
        this.ratio = 0;
        this._build();
    }
    refreshAnimationRatio() {
        /*
        this.ratio = this._entity._scene.getAnimationRatio();
        // set animation speed
        this._idle.speedRatio = this.entityData.animations["IDLE"].speed * this.ratio;
        this._walk.speedRatio = this.entityData.animations["WALK"].speed * this.ratio;
        this._attack.speedRatio = this.entityData.animations["ATTACK"].speed * this.ratio;
        this._death.speedRatio = this.entityData.animations["DEATH"].speed * this.ratio;
        */
    }
    _build() {
        var _a;
        // build animation list and properties
        let animations = (_a = this._entity.raceData.vat.animations) !== null && _a !== void 0 ? _a : [];
        let i = 0;
        for (let key in animations) {
            let anim = animations[key];
            anim.key = key;
            anim.index = i;
            (anim.ranges = this.entityData.animationRanges[i]), (this._animations[key] = anim);
            i++;
        }
        //console.log("animations", this._animations);
        // set default animation
        this._currentAnim = this.getAnimation("IDLE");
        this._prevAnim = this.getAnimation("WALK");
    }
    getAnimation(key) {
        return this._animations[key];
    }
    // This method will compute the VAT offset to use so that the animation starts at frame #0 for VAT time = time passed as 3rd parameter
    computeOffsetInAnim(fromFrame, toFrame, time, delta) {
        const totalFrames = toFrame - fromFrame + 1;
        const t = (time * delta) / totalFrames;
        const frame = Math.floor((t - Math.floor(t)) * totalFrames);
        return totalFrames - frame;
    }
    setAnimationParameters(vec, currentAnim, delta = 60) {
        var _a;
        const animIndex = (_a = currentAnim.index) !== null && _a !== void 0 ? _a : 0;
        const anim = this.entityData.animationRanges[animIndex];
        const from = Math.floor(anim.from);
        const to = Math.floor(anim.to);
        this.fromFrame = from;
        this.toFrame = to - 1;
        this._currentAnimVATTimeAtStart = this.entityData.vat.time;
        this._currentAnimVATOffset = this.computeOffsetInAnim(this.fromFrame, this.toFrame, this._currentAnimVATTimeAtStart, delta);
        vec.set(this.fromFrame, this.toFrame, this._currentAnimVATOffset, delta); // skip one frame to avoid weird artifacts
    }
    //
    checkIfPlayerIsMoving(currentPos, nextPos, epsilon = 0.01) {
        return !currentPos.equalsWithEpsilon(nextPos, epsilon);
    }
    // determine what animation should be played
    animate(entity) {
        let currentPos = entity.getPosition();
        let nextPos = entity.moveController.getNextPosition();
        entity.isMoving = false;
        // if player has died
        if (entity.anim_state === types_1.EntityState.DEAD) {
            this._currentAnim = this.getAnimation("DEATH");
            // if player is attacking
        }
        else if (entity.anim_state === types_1.EntityState.ATTACK_01) {
            this._currentAnim = this.getAnimation("ATTACK_01");
            // if player is attacking
        }
        else if (entity.anim_state === types_1.EntityState.ATTACK_02) {
            this._currentAnim = this.getAnimation("ATTACK_02");
            // if player is attacking
        }
        else if (entity.anim_state === types_1.EntityState.SPELL_CASTING) {
            this._currentAnim = this.getAnimation("SPELL_CASTING");
            // if player is attacking
        }
        else if (entity.anim_state === types_1.EntityState.SPELL_CAST) {
            this._currentAnim = this.getAnimation("SPELL_CAST");
            // if player is moving
        }
        else if (this.checkIfPlayerIsMoving(currentPos, nextPos) && entity.health > 0) {
            //console.log("PLAYER IS STILL MOVING...");
            this._currentAnim = this.getAnimation("WALK");
            entity.isMoving = true;
            // todo: I hate this, but I have no idea how to fix this in a better way at this stage...
            /*
            if (entity._input && !entity._input.player_can_move) {
                this._currentAnim = this.getAnimation('IDLE');
                entity.isMoving = false;
            }*/
            // else play idle
        }
        else {
            this._currentAnim = this.getAnimation("IDLE");
        }
        if (entity.type === "player" && entity.anim_state !== types_1.EntityState.IDLE) {
            //console.log(entity.anim_state, EntityState[entity.anim_state], this._animations);
        }
    }
    // play animation
    play(player) {
        const fpsAdjusted = 60 * this._currentAnim.speed;
        //
        if (!this.mesh)
            return false;
        // play animation and stop previous animation
        if (this._currentAnim != null && this._prevAnim !== this._currentAnim) {
            //console.log("[PLAY 1] ANIMATION CHANGED for", player.name, this._prevAnim.name, "-", this._currentAnim.name);
            this.setAnimationParameters(this.mesh.instancedBuffers.bakedVertexAnimationSettingsInstanced, this._currentAnim, fpsAdjusted);
            player.meshController.equipments.forEach((itemMesh) => {
                this.setAnimationParameters(itemMesh.instancedBuffers.bakedVertexAnimationSettingsInstanced, this._currentAnim, fpsAdjusted);
            });
            this._prevAnim = this._currentAnim;
            this.endOfLoop = false;
        }
        //
        const currentVATTime = this.entityData.vat.time;
        const currentAnimFrame = Math.floor((currentVATTime - this._currentAnimVATTimeAtStart) * fpsAdjusted);
        // if animation is loop=false; and finished playing
        if (currentAnimFrame >= this.toFrame - this.fromFrame && this._currentAnim.loop === false && this.endOfLoop === false) {
            //console.log("[PLAY 2] ANIMATION FINISHED, STOP ANIMATION ", this.currentFrame, this.targetFrame);
            this.mesh.instancedBuffers.bakedVertexAnimationSettingsInstanced.set(this.toFrame - 1, this.toFrame, this._currentAnimVATOffset, fpsAdjusted);
            this.endOfLoop = true;
            player.meshController.equipments.forEach((itemMesh) => {
                //console.log("ITEM ANIMATION FINISHED, STOP ANIMATION ", this.currentFrame, this.targetFrame);
                itemMesh.instancedBuffers.bakedVertexAnimationSettingsInstanced.set(this.toFrame - 1, this.toFrame, this._currentAnimVATOffset, fpsAdjusted);
            });
        }
    }
    refreshItems() {
        this._entity.meshController.equipments.forEach((itemMesh) => {
            this.setAnimationParameters(itemMesh.instancedBuffers.bakedVertexAnimationSettingsInstanced, this._currentAnim);
        });
    }
    refreshItem(itemMesh) {
        this.setAnimationParameters(itemMesh.instancedBuffers.bakedVertexAnimationSettingsInstanced, this._currentAnim);
    }
    refreshAnimation() {
        this._prevAnim = false;
    }
}
exports.EntityAnimator = EntityAnimator;
