"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Watermark = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const control_1 = require("@babylonjs/gui/2D/controls/control");
class Watermark {
    constructor(ui) {
        this._ui = ui;
        // create watermark elements
        const columnRect = new rectangle_1.Rectangle("column");
        columnRect.widthInPixels = 250;
        columnRect.heightInPixels = 250;
        columnRect.background = "transparent";
        columnRect.thickness = 0;
        columnRect.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        columnRect.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        columnRect.isVisible = false;
        this._ui.MAIN_ADT.addControl(columnRect);
        this._bloc = columnRect;
        // logo
        var imgLogo = new image_1.Image("imgLogo", "./images/logo.png");
        imgLogo.stretch = image_1.Image.STRETCH_UNIFORM;
        imgLogo.top = "0px";
        imgLogo.width = 1;
        imgLogo.height = "120px;";
        imgLogo.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        columnRect.addControl(imgLogo);
    }
}
exports.Watermark = Watermark;
