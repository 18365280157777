"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Abilities = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Panel_1 = require("./Panel");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const Theme_1 = require("../Theme");
const scrollViewer_1 = require("@babylonjs/gui/2D/controls/scrollViewers/scrollViewer");
class Panel_Abilities extends Panel_1.Panel {
    constructor(_UI, _currentPlayer, options) {
        super(_UI, _currentPlayer, options);
        this.createContent();
        // dynamic events
        let entity = this._currentPlayer.entity;
        entity.player_data.abilities.onAdd((item, sessionId) => {
            this.refresh();
            // todo: could be a performance issue here?
            // orion to keep an eye on this one
            item.onChange((item, sessionId) => {
                this.refresh();
            });
            item.onRemove((item, sessionId) => {
                this.refresh();
            });
        });
    }
    // open panel
    open() {
        super.open();
        this.refresh();
    }
    // create panel
    createContent() {
        let panel = this._panelContent;
        // if already exists
        panel.children.forEach((el) => {
            el.dispose();
        });
        // add scrollable container
        const scrollViewer = new scrollViewer_1.ScrollViewer("scrollViewer");
        scrollViewer.width = 0.99;
        scrollViewer.height = 0.92;
        scrollViewer.top = 0;
        scrollViewer.thickness = 0;
        scrollViewer.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        scrollViewer.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        panel.addControl(scrollViewer);
        // panel title
        const skillsPanelStack = new stackPanel_1.StackPanel("skillsPanelStack");
        skillsPanelStack.width = 1;
        skillsPanelStack.adaptHeightToChildren = true;
        skillsPanelStack.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        skillsPanelStack.setPaddingInPixels(5, 5, 5, 5);
        scrollViewer.addControl(skillsPanelStack);
        this.addAbilities(skillsPanelStack);
        //this.addAbilities(skillsPanelStack);
    }
    addAbilities(skillsPanelStack) {
        let Abilities = this._game.loadGameData("abilities");
        for (let key in Abilities) {
            // get ability details
            let ability = Abilities[key];
            let skillsPanel = new rectangle_1.Rectangle("abilityCont" + ability.key);
            skillsPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            skillsPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            skillsPanel.top = "0px";
            skillsPanel.left = "0px;";
            skillsPanel.width = 1;
            skillsPanel.height = "50px";
            skillsPanel.background = "#CCC";
            skillsPanel.thickness = 1;
            skillsPanel.paddingLeft = "5px;";
            skillsPanel.paddingBottom = "5px;";
            (0, Theme_1.applyTheme)(skillsPanel);
            skillsPanelStack.addControl(skillsPanel);
            // add icon
            let imageBLoc = new rectangle_1.Rectangle("imageBLoc" + ability.key);
            imageBLoc.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            imageBLoc.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            imageBLoc.top = "0px";
            imageBLoc.left = "0px;";
            imageBLoc.width = "40px;";
            imageBLoc.height = "40px;";
            imageBLoc.thickness = 0;
            skillsPanel.addControl(imageBLoc);
            var imageData = this._loadedAssets[ability.icon];
            var img = new image_1.Image("itemImage_" + ability.key, imageData);
            img.stretch = image_1.Image.STRETCH_FILL;
            imageBLoc.addControl(img);
            // on hover tooltip
            imageBLoc.onPointerEnterObservable.add(() => {
                this._UI._Tooltip.refresh("ability", ability, imageBLoc, "right", "center");
            });
            // on hover tooltip
            imageBLoc.onPointerOutObservable.add(() => {
                this._UI._Tooltip.close();
            });
            const tooltipName = new textBlock_1.TextBlock("abilityName" + ability.key);
            tooltipName.color = "#FFF";
            tooltipName.top = "5px";
            tooltipName.left = "50px";
            tooltipName.fontSize = "18px;";
            tooltipName.resizeToFit = true;
            tooltipName.text = ability.title;
            tooltipName.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            tooltipName.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            tooltipName.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            tooltipName.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            skillsPanel.addControl(tooltipName);
            const abilityDescr = new textBlock_1.TextBlock("abilityDescr" + ability.key);
            abilityDescr.color = "rgba(255,255,255,.6)";
            abilityDescr.top = "0px";
            abilityDescr.left = "50px";
            abilityDescr.fontSize = "12px;";
            abilityDescr.resizeToFit = true;
            abilityDescr.text = ability.description;
            abilityDescr.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            abilityDescr.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
            abilityDescr.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
            abilityDescr.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            skillsPanel.addControl(abilityDescr);
        }
    }
    objToString(obj) {
        let str = "Required ";
        for (const [p, val] of Object.entries(obj)) {
            str += `| ${p}:${val} `;
        }
        return str;
    }
    ///////////////////////////////////////
    ///////////////////////////////////////
    // INVENTORY PANEL
    refresh() {
        this.createContent();
    }
}
exports.Panel_Abilities = Panel_Abilities;
