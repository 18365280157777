"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestsHelper = void 0;
class QuestsHelper {
    static findQuestTargetName(location, targetName, quantity) {
        var _a;
        let spawns = (_a = location.dynamic.spawns) !== null && _a !== void 0 ? _a : [];
        let found = "";
        spawns.forEach((element) => {
            if (element.key === targetName) {
                found = element.name;
            }
        });
        if (quantity > 1) {
            found += "s";
        }
        return found;
    }
}
exports.QuestsHelper = QuestsHelper;
