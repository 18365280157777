"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiUrl = exports.isLocal = void 0;
const isLocal = function () {
    return window.location.host === "localhost:8080";
};
exports.isLocal = isLocal;
const apiUrl = function (port) {
    let url = "https://" + window.location.hostname;
    if (isLocal()) {
        url = "http://localhost:" + port;
    }
    return url;
};
exports.apiUrl = apiUrl;
