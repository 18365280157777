"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RessurectBox = void 0;
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Theme_1 = require("./Theme");
const button_1 = require("@babylonjs/gui/2D/controls/button");
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const types_1 = require("../../../shared/types");
class RessurectBox {
    constructor(_UI, _currentPlayer) {
        this._UI = _UI;
        this._playerUI = _UI._playerUI;
        this._game = _UI._game;
        this._currentPlayer = _currentPlayer;
        this._createUI();
    }
    _createUI() {
        // add tooltip
        const revivePanel = new rectangle_1.Rectangle("revivePanel");
        revivePanel.top = "0px";
        revivePanel.width = "200px";
        revivePanel.height = "90px;";
        revivePanel.thickness = 1;
        revivePanel.background = (0, Theme_1.getBg)();
        revivePanel.isVisible = false;
        revivePanel.isPointerBlocker = true;
        revivePanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        revivePanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        this._playerUI.addControl(revivePanel);
        this.revivePanel = revivePanel;
        const revivePanelText = new textBlock_1.TextBlock("revivePanelText");
        revivePanelText.height = "30px;";
        revivePanelText.text = "You have died.";
        revivePanelText.top = "10px;";
        revivePanelText.left = 0;
        revivePanelText.fontSize = "24px;";
        revivePanelText.color = "white";
        revivePanelText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        revivePanelText.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        revivePanelText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        revivePanelText.horizontalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        revivePanel.addControl(revivePanelText);
        const reviveButton = button_1.Button.CreateSimpleButton("reviveButton", "RESSURECT");
        reviveButton.top = "-10px;";
        reviveButton.left = "0px;";
        reviveButton.width = "180px;";
        reviveButton.height = "30px";
        reviveButton.color = "white";
        reviveButton.background = "#000";
        reviveButton.thickness = 1;
        reviveButton.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        reviveButton.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        revivePanel.addControl(reviveButton);
        reviveButton.onPointerDownObservable.add(() => {
            this._game.sendMessage(types_1.ServerMsg.PLAYER_RESSURECT);
        });
    }
    open() {
        this.revivePanel.isVisible = true;
    }
    close() {
        this.revivePanel.isVisible = false;
    }
}
exports.RessurectBox = RessurectBox;
