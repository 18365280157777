"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySelectedBar = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Theme_1 = require("./Theme");
const index_1 = require("../../../shared/Utils/index");
class EntitySelectedBar {
    constructor(_UI, options = { panelName: "", currentPlayer: false }) {
        this._playerUI = _UI._playerUI;
        this.LABELS_ADT = _UI.LABELS_ADT;
        this._scene = _UI._scene;
        this._game = _UI._game;
        this._options = options;
        this._loadedAssets = _UI._loadedAssets;
        this._createUI();
    }
    _createUI() {
        let alignHoriz = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        let panelWidth = 250;
        let barWidth = 188;
        let leftMargin = 15;
        let topMargin = 15;
        if (this._options.panelName === "target") {
            leftMargin = 30;
            topMargin = 85;
        }
        ////////////////////////////////////
        //////////////////// panel
        const selectedEntityBar = (0, Theme_1.generatePanel)("selected" + this._options.panelName, panelWidth + "px;", "62px", topMargin + "px", leftMargin + "px");
        selectedEntityBar.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        selectedEntityBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        this._playerUI.addControl(selectedEntityBar);
        const paddingPanel = new rectangle_1.Rectangle("paddingPanel");
        paddingPanel.width = 1;
        paddingPanel.height = 1;
        paddingPanel.thickness = 0;
        paddingPanel.setPaddingInPixels((0, Theme_1.getPadding)());
        selectedEntityBar.addControl(paddingPanel);
        const imgPanel = new rectangle_1.Rectangle("imgPanel");
        imgPanel.width = "45px";
        imgPanel.height = "45px;";
        imgPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        imgPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        imgPanel.background = "black";
        imgPanel.thickness = 0;
        paddingPanel.addControl(imgPanel);
        if (this._options.currentPlayer !== false) {
            let entity = this._options.currentPlayer;
            var imageData = this._loadedAssets[entity.icon];
            var img = new image_1.Image("itemImage_" + entity.key, imageData);
            img.stretch = image_1.Image.STRETCH_FILL;
            imgPanel.addControl(img);
        }
        ////////////////////////////////////
        //////////////////// health bar
        const healthBar = new rectangle_1.Rectangle("healthBar");
        healthBar.top = "0px;";
        healthBar.left = "50px";
        healthBar.width = barWidth + "px;";
        healthBar.height = "25px";
        healthBar.thickness = 0;
        healthBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        healthBar.horizontalAlignment = alignHoriz;
        paddingPanel.addControl(healthBar);
        const healthBarInside = new rectangle_1.Rectangle("healthBarInside");
        healthBarInside.top = "0px;";
        healthBarInside.left = "0px;";
        healthBarInside.width = 1;
        healthBarInside.thickness = 0;
        healthBarInside.height = 1;
        healthBarInside.background = "#75ed1b";
        healthBarInside.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        healthBarInside.horizontalAlignment = alignHoriz;
        healthBar.addControl(healthBarInside);
        const healthBarText = new textBlock_1.TextBlock("healthBarText", "");
        healthBarText.text = "0";
        healthBarText.color = "#FFF";
        healthBarText.top = "0px";
        healthBarText.left = "-5px";
        healthBarText.fontSize = "14px;";
        healthBarText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        healthBarText.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        healthBarText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        healthBarText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        healthBar.addControl(healthBarText);
        /////////////////////////////////////
        //////////////////// mana bar
        const manaBar = new rectangle_1.Rectangle("manaBar");
        manaBar.top = "28px;";
        manaBar.left = "50px";
        manaBar.width = barWidth + "px;";
        manaBar.height = "17px";
        manaBar.thickness = 0;
        manaBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        manaBar.horizontalAlignment = alignHoriz;
        paddingPanel.addControl(manaBar);
        const manaBarInside = new rectangle_1.Rectangle("manaBarInside");
        manaBarInside.top = "0px;";
        manaBarInside.left = "0px;";
        manaBarInside.width = 1;
        manaBarInside.thickness = 0;
        manaBarInside.height = 1;
        manaBarInside.background = "blue";
        manaBarInside.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        manaBarInside.horizontalAlignment = alignHoriz;
        manaBar.addControl(manaBarInside);
        const manaBarText = new textBlock_1.TextBlock("manaBarText");
        manaBarText.text = "0";
        manaBarText.color = "#FFF";
        manaBarText.top = "1px";
        manaBarText.left = "-5px";
        manaBarText.fontSize = "12px;";
        manaBarText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        manaBarText.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        manaBarText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        manaBarText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        manaBar.addControl(manaBarText);
        ///////////////////////////////////////////////////
        ////////////////////////////////////
        //////////////////// entity name
        const entityNameTxt = new textBlock_1.TextBlock("entityNameTxt", "");
        entityNameTxt.text = "XXXXXX";
        entityNameTxt.color = "#FFF";
        entityNameTxt.top = "0px";
        entityNameTxt.left = "5px";
        entityNameTxt.fontSize = "14px;";
        entityNameTxt.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        entityNameTxt.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        entityNameTxt.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        healthBar.addControl(entityNameTxt);
        const entityLevelTxt = new textBlock_1.TextBlock("entityLevelTxt", "");
        entityLevelTxt.text = "XXXXX";
        entityLevelTxt.color = "#FFF";
        entityLevelTxt.top = "0px";
        entityLevelTxt.left = "5px";
        entityLevelTxt.fontSize = "12px;";
        entityLevelTxt.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        entityLevelTxt.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        entityLevelTxt.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        entityLevelTxt.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        manaBar.addControl(entityLevelTxt);
        /////////////////////////////////////
        this._entityNameTxt = entityNameTxt;
        this._entityLevelTxt = entityLevelTxt;
        this._selectedEntityBar = selectedEntityBar;
        this._healthBar = healthBar;
        this._healthBarInside = healthBarInside;
        this._healthBarText = healthBarText;
        this._manaBar = manaBar;
        this._manaBarInside = manaBarInside;
        this._manaBarText = manaBarText;
        // hide it by default
        this._selectedEntityBar.isVisible = false;
    }
    setTarget(target) {
        // show selected
        this._selectedEntityBar.isVisible = true;
        // set as selected
        this._game.selectedEntity = target;
        // update data
        this._entityNameTxt.text = target.name;
        this._entityLevelTxt.text = "Lvl " + target.level;
        //
        this.setData(target);
    }
    setData(entity) {
        // health
        let health = (0, index_1.roundTo)(entity.health, 0);
        let healthWidth = entity.health / entity.maxHealth;
        this._healthBarInside.width = healthWidth;
        this._healthBarText.text = health + "/" + entity.maxHealth;
        // mana
        let mana = (0, index_1.roundTo)(entity.mana, 0);
        let manaWidth = entity.mana / entity.maxMana;
        this._manaBarInside.width = manaWidth;
        this._manaBarText.text = mana + "/" + entity.maxMana;
        // update data
        this._entityNameTxt.text = entity.name;
        this._entityLevelTxt.text = "Lvl " + entity.level;
    }
    // refresh panel
    update() {
        let entity = this._game.selectedEntity ? this._game.selectedEntity : false;
        if (this._options.currentPlayer !== false) {
            entity = this._options.currentPlayer;
        }
        if (entity) {
            //console.log('[UPDATE]', entity.name, entity.health);
            this.setData(entity);
        }
    }
}
exports.EntitySelectedBar = EntitySelectedBar;
