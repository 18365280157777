"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundController = void 0;
const sound_1 = require("@babylonjs/core/Audio/sound");
class SoundController {
    constructor(gamescene) {
        this.volume = 0.2;
        this.sounds = new Map();
        this._gamescene = gamescene;
    }
    play(key, loop = false) {
        // start  music
        console.log("[SOUND] loading " + key);
        if (!this.sounds.has(key)) {
            let soundData = this._gamescene._game._loadedAssets[key];
            let sound = new sound_1.Sound(key, soundData, this._gamescene._scene, () => {
                this.sounds.set(key, sound);
                sound.play();
                //console.log("[SOUND] playing " + key, sound);
            }, {
                volume: this.volume,
                loop: loop,
            });
        }
        else {
            this.sounds.get(key).play(loop);
            //console.log("[SOUND] playing from cache " + key);
        }
    }
}
exports.SoundController = SoundController;
