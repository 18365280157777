"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotBar = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const Theme_1 = require("./Theme");
const types_1 = require("../../../shared/types");
class HotBar {
    constructor(_UI, _currentPlayer) {
        this._playerUI = _UI._playerUI;
        this._currentPlayer = _currentPlayer;
        this._room = _UI._room;
        this._loadedAssets = _UI._loadedAssets;
        this._game = _UI._game;
        this._UI = _UI;
        // create ui
        this._createUI();
        // add ui events
        let entity = this._currentPlayer.entity;
        entity.player_data.hotbar.onAdd((item, sessionId) => {
            this._createUI();
            // todo: could be a performance issue here?
            // orion to keep an eye on this one
            item.onChange((item, sessionId) => {
                this._createUI();
            });
            item.onRemove((item, sessionId) => {
                this._createUI();
            });
        });
    }
    _createUI() {
        let width = 460;
        let abilityRect = [];
        if (this._abilityUI) {
            this._abilityUI.dispose();
        }
        const abilityMainPanel = (0, Theme_1.generatePanel)("abilityPanel", "470px;", "62px", "-35px", "0px");
        abilityMainPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        abilityMainPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        abilityMainPanel.isPointerBlocker = true;
        this._playerUI.addControl(abilityMainPanel);
        const paddingPanel = new rectangle_1.Rectangle("paddingPanel");
        paddingPanel.width = 1;
        paddingPanel.height = 1;
        paddingPanel.thickness = 0;
        paddingPanel.setPaddingInPixels((0, Theme_1.getPadding)());
        abilityMainPanel.addControl(paddingPanel);
        this._abilityUI = abilityMainPanel;
        // add stack panel
        const abilityPanel = new rectangle_1.Rectangle("abilityPanel");
        abilityPanel.top = "0px;";
        abilityPanel.width = width + "px";
        abilityPanel.adaptHeightToChildren = true;
        abilityPanel.thickness = 0;
        abilityPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        abilityPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        paddingPanel.addControl(abilityPanel);
        for (let i = 1; i <= this._game.config.PLAYER_HOTBAR_SIZE; i++) {
            // calculate responsive width and height
            let iconGutter = 4;
            let iconWidth = width / this._game.config.PLAYER_HOTBAR_SIZE - iconGutter;
            let iconLeft = iconWidth + iconGutter;
            let leftMargin = i > 1 ? (i - 1) * iconLeft + "px" : "0px";
            // container
            var headlineRect = new rectangle_1.Rectangle("ability_" + i);
            headlineRect.top = "0px";
            headlineRect.left = leftMargin;
            headlineRect.width = iconWidth + "px";
            headlineRect.height = iconWidth + "px";
            headlineRect.thickness = 0;
            headlineRect.background = "rgba(255,255,255,.2)";
            headlineRect.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            headlineRect.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            abilityPanel.addControl(headlineRect);
            abilityRect[i] = headlineRect;
        }
        // add hotbar icons
        if (this._currentPlayer.entity.player_data.hotbar) {
            this._currentPlayer.entity.player_data.hotbar.forEach((data) => {
                let hotbarData;
                if (data.type === "item") {
                    hotbarData = this._game.getGameData("item", data.key);
                }
                if (data.type === "ability") {
                    hotbarData = this._game.getGameData("ability", data.key);
                }
                if (hotbarData) {
                    this.addIcon(data.digit, data, hotbarData, abilityRect[data.digit]);
                }
            });
        }
    }
    addIcon(digit, hotbar, hotbarData, headlineRect) {
        var img = new image_1.Image("ability_image_" + digit, "./images/icons/" + hotbarData.icon + ".png");
        img.stretch = image_1.Image.STRETCH_FILL;
        headlineRect.addControl(img);
        headlineRect.onPointerEnterObservable.add(() => {
            this.showTooltip(hotbar.type, hotbarData, headlineRect);
        });
        headlineRect.onPointerOutObservable.add(() => {
            this.hideTooltip();
        });
        headlineRect.onPointerClickObservable.add(() => {
            var _a, _b, _c;
            if (!this._currentPlayer.abilityController.isCasting) {
                this._game.sendMessage(types_1.ServerMsg.PLAYER_HOTBAR_ACTIVATED, {
                    senderId: this._room.sessionId,
                    targetId: (_c = (_b = (_a = this._game) === null || _a === void 0 ? void 0 : _a.selectedEntity) === null || _b === void 0 ? void 0 : _b.sessionId) !== null && _c !== void 0 ? _c : false,
                    digit: digit,
                });
            }
        });
        // add ability number
        var abilityNumber = new rectangle_1.Rectangle("abilityNumber" + digit + "_cooldown");
        abilityNumber.top = "0px";
        abilityNumber.left = "0px";
        abilityNumber.width = "15px";
        abilityNumber.height = "15px;";
        abilityNumber.thickness = 0;
        abilityNumber.isVisible = true;
        abilityNumber.background = "rgba(0,0,0,.7)";
        abilityNumber.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        abilityNumber.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        headlineRect.addControl(abilityNumber);
        var roomTxt = new textBlock_1.TextBlock("ability_text_" + digit);
        roomTxt.text = "" + digit;
        roomTxt.fontSize = "12px";
        roomTxt.color = "#FFF";
        roomTxt.fontWeight = "bold";
        roomTxt.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        roomTxt.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        abilityNumber.addControl(roomTxt);
        // add cooldown
        var abilityCooldown = new rectangle_1.Rectangle("ability_" + digit + "_cooldown");
        abilityCooldown.top = 0;
        abilityCooldown.left = 0;
        abilityCooldown.width = 1;
        abilityCooldown.height = 0;
        abilityCooldown.thickness = 0;
        abilityCooldown.isVisible = true;
        abilityCooldown.zIndex = 1;
        abilityCooldown.background = "rgba(0,0,0,.7)";
        abilityCooldown.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        abilityCooldown.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        headlineRect.addControl(abilityCooldown);
    }
    showTooltip(type, hotbarData, headlineRect) {
        if (type === "item") {
            this._UI._Tooltip.refresh("item", hotbarData, headlineRect, "center", "top");
        }
        if (type === "ability") {
            this._UI._Tooltip.refresh("ability", hotbarData, headlineRect, "center", "top");
        }
    }
    hideTooltip() {
        this._UI._Tooltip.close();
    }
}
exports.HotBar = HotBar;
