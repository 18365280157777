"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VatController = void 0;
const VatHelper_1 = require("../Entities/Common/VatHelper");
const bakedVertexAnimationManager_1 = require("@babylonjs/core/BakedVertexAnimation/bakedVertexAnimationManager");
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
const vertexAnimationBaker_1 = require("@babylonjs/core/BakedVertexAnimation/vertexAnimationBaker");
const MeshHelper_1 = require("../Entities/Common/MeshHelper");
const texture_1 = require("@babylonjs/core/Materials/Textures/texture");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const types_1 = require("../../shared/types");
const buffer_1 = require("@babylonjs/core/Buffers/buffer");
const pbrCustomMaterial_1 = require("@babylonjs/materials/custom/pbrCustomMaterial");
class JavascriptDataDownloader {
    constructor(data = {}) {
        this.data = data;
    }
    download(type_of = "text/plain", filename = "data.txt") {
        let body = document.body;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([JSON.stringify(this.data, null, 2)], {
            type: type_of,
        }));
        a.setAttribute("download", filename);
        body.appendChild(a);
        a.click();
        body.removeChild(a);
    }
}
class VatController {
    get entityData() {
        return this._entityData;
    }
    get skeletonData() {
        return this._skeletonData;
    }
    constructor(game, spawns) {
        this._spawns = [];
        this._entityData = new Map();
        this._vatData = new Map();
        this._skeletonData = new Map();
        this._game = game;
        this._spawns = spawns;
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            // always load humanoid
            yield this.prepareVat(this._game.getGameData("race", "humanoid"));
            // load all others
            for (let spawn of this._spawns) {
                let race = this._game.getGameData("race", spawn.race);
                if (!this._entityData.has(race.vat)) {
                    yield this.prepareVat(race);
                }
            }
        });
    }
    fetchVAT(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = "./models/races/vat/" + key + ".json";
            console.log(url);
            const response = yield fetch(url);
            const movies = yield response.json();
            return movies;
        });
    }
    prepareVat(race) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = race.vat.key;
            if (!this._entityData.has(key)) {
                //console.log("[prepareVat] 2 " + key, race);
                // get vat data
                const bakedAnimationJson = yield this.fetchVAT(key);
                const { animationGroups, skeletons } = this._game._loadedAssets["VAT_" + key];
                const skeleton = skeletons[0];
                // get selected animations
                animationGroups.forEach((ag) => ag.stop());
                const selectedAnimationGroups = this.getAnimationGroups(animationGroups, race.vat.animations);
                // calculate animations ranges
                const ranges = (0, VatHelper_1.calculateRanges)(selectedAnimationGroups);
                // create vat manager
                const b = new vertexAnimationBaker_1.VertexAnimationBaker(this._game.scene, skeleton);
                const manager = new bakedVertexAnimationManager_1.BakedVertexAnimationManager(this._game.scene);
                // load prebaked vat animations
                let bufferFromMesh = b.loadBakedVertexDataFromJSON(bakedAnimationJson);
                manager.texture = b.textureFromBakedVertexData(bufferFromMesh);
                // save vat
                this._entityData.set(key, {
                    name: key,
                    meshes: new Map(),
                    animationRanges: ranges,
                    selectedAnimationGroups: selectedAnimationGroups,
                    vat: manager,
                    skeleton: skeleton,
                    items: new Map(),
                    bones: race.vat.bones,
                    animations: race.vat.animations,
                });
            }
            return this._entityData.get(key);
        });
    }
    /**
     * This function remove any unwanted meshes, apply the right materials and more
     *
     * @param baseMeshes
     * @param data
     * @returns
     */
    makeHumanoid(rawMesh, data) {
        let keepArray = [
            data.head, //
            "Base_ArmLeft",
            "Base_ArmRight",
            "Base_Body",
            "Base_LegLeft",
            "Base_LegRight",
            "Armor_Cape",
        ];
        rawMesh.getChildMeshes(false).forEach((element) => {
            var n = element.id.lastIndexOf(".");
            var result = element.id.substring(n + 1);
            if (!keepArray.includes(result)) {
                element.dispose();
                //console.error("DISPOSING", element.id);
            }
            else {
                //element.removeVerticesData(VertexBuffer.MatricesIndicesKind)
                //element.removeVerticesData(VertexBuffer.MatricesWeightsKind)
                //console.log(element.getVerticesDataKinds());
                //console.log("KEEPING", element.id);
            }
        });
        return rawMesh;
    }
    // this should regenerate entity mesh with any head/equipemnt/material needed
    refreshMesh(entity) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("[VAT] refresh mesh", entity);
            // reset parent of any dynamic items
            if (entity.meshController.equipments.size > 0) {
                entity.meshController.equipments.forEach((equipment) => {
                    equipment.setParent(entity);
                });
            }
            // remove existing mesh
            let mesh = entity.entityData.meshes.get(entity.sessionId);
            if (mesh) {
                mesh.dispose();
                entity.entityData.meshes.delete(entity.sessionId);
            }
            // create new mesh based on the new data
            this.prepareMesh(entity);
            // wait a bit before adding the new mesh to the entity
            setTimeout(() => {
                entity.meshController.createMesh();
                entity.animatorController.mesh = entity.meshController.mesh;
                entity.animatorController.refreshAnimation();
            }, 200);
        });
    }
    prepareMesh(entity) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = entity.race;
            let race = this._game.getGameData("race", key);
            let meshId = VatController.findMeshKey(race, entity);
            if (!race) {
                console.log("Race does not exists", key);
            }
            // gets or prepare vat
            let vat = yield this.prepareVat(race);
            // gets mesh if already prepared
            if (vat.meshes.has(meshId)) {
                return false;
            }
            // clone raw mesh
            let rawMesh = this._game._loadedAssets["RACE_" + key].meshes[0].clone("TEST");
            rawMesh.name = "_root_race_" + key;
            // reset positions
            rawMesh.position.setAll(0);
            rawMesh.scaling.setAll(1);
            rawMesh.rotationQuaternion = null;
            rawMesh.rotation.setAll(0);
            // if customizable
            // todo: more work here to make it better
            if (race.customizable) {
                rawMesh = this.makeHumanoid(rawMesh, entity);
            }
            // merge mesh with skeleton
            let modelMeshMerged = (0, MeshHelper_1.mergeMeshAndSkeleton)(rawMesh, vat.skeleton);
            // setup vat
            if (modelMeshMerged) {
                // update material
                this.prepareMaterial(modelMeshMerged, race.key, entity.material);
                // set mesh
                modelMeshMerged.registerInstancedBuffer("bakedVertexAnimationSettingsInstanced", 4);
                modelMeshMerged.instancedBuffers.bakedVertexAnimationSettingsInstanced = new math_vector_1.Vector4(0, 0, 0, 0);
                modelMeshMerged.bakedVertexAnimationManager = vat.vat;
                // save for later use
                vat.meshes.set(meshId, modelMeshMerged);
                // hide mesh
                modelMeshMerged.setEnabled(false);
                rawMesh.dispose();
            }
        });
    }
    static findMeshKey(race, entity) {
        let meshId = race.key;
        if (race.customizable) {
            meshId = entity.head + "_" + entity.material;
            //meshId = entity.sessionId;
        }
        return meshId;
    }
    prepareItemForVat(entityData, itemKey) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            // if already prepared, stop
            if (entityData.items.has(itemKey)) {
                return false;
            }
            // load item
            let item = this._game.getGameData("item", itemKey);
            let slot = item.equippable ? types_1.PlayerSlots[item.equippable.slot] : 0;
            let boneId = entityData.bones[slot]; // bones come from entityData
            if (!boneId)
                return false;
            // clone raw mesh
            let rawMesh = this._game._loadedAssets["ITEM_" + item.key].meshes[0].clone("TEST");
            rawMesh.name = "_root_item_" + itemKey;
            rawMesh.position.copyFrom(entityData.skeleton.bones[boneId].getAbsolutePosition());
            rawMesh.rotationQuaternion = undefined;
            rawMesh.rotation.set(0, Math.PI * 1.5, 0);
            rawMesh.scaling.setAll(1);
            // if mesh offset required
            let equipOptions = item.equippable;
            if (equipOptions.scale) {
                rawMesh.scaling = new math_vector_1.Vector3(equipOptions.scale, equipOptions.scale, equipOptions.scale);
            }
            if (equipOptions.offset_x) {
                rawMesh.position.x += equipOptions.offset_x;
            }
            if (equipOptions.offset_y) {
                rawMesh.position.y += equipOptions.offset_y;
            }
            if (equipOptions.offset_z) {
                rawMesh.position.z += equipOptions.offset_z;
            }
            // if rotationFix needed
            if (equipOptions.rotation_x || equipOptions.rotation_y || equipOptions.rotation_z) {
                // You cannot use a rotationQuaternion followed by a rotation on the same mesh. Once a rotationQuaternion is applied any subsequent use of rotation will produce the wrong orientation, unless the rotationQuaternion is first set to null.
                rawMesh.rotationQuaternion = null;
                rawMesh.rotation.set((_a = equipOptions.rotation_x) !== null && _a !== void 0 ? _a : 0, (_b = equipOptions.rotation_y) !== null && _b !== void 0 ? _b : 0, (_c = equipOptions.rotation_z) !== null && _c !== void 0 ? _c : 0);
            }
            // merge mesh
            let itemMesh = (0, MeshHelper_1.mergeMesh)(rawMesh, itemKey);
            if (itemMesh) {
                itemMesh.name = entityData.name + "_" + itemMesh.name;
                // update material
                if (item.material) {
                    VatController.loadItemMaterial(this._game.scene, itemMesh, item.material);
                }
                // attach to VAT
                itemMesh.skeleton = entityData.skeleton;
                itemMesh.bakedVertexAnimationManager = entityData.vat;
                itemMesh.registerInstancedBuffer("bakedVertexAnimationSettingsInstanced", 4);
                itemMesh.instancedBuffers.bakedVertexAnimationSettingsInstanced = new math_vector_1.Vector4(0, 0, 0, 0);
                // manually set MatricesIndicesKind & MatricesWeightsKind
                // https://doc.babylonjs.com/features/featuresDeepDive/mesh/bonesSkeletons#preparing-mesh
                const totalCount = itemMesh.getTotalVertices();
                const weaponMI = [];
                const weaponMW = [];
                for (let i = 0; i < totalCount; i++) {
                    weaponMI.push(boneId, 0, 0, 0);
                    weaponMW.push(1, 0, 0, 0);
                }
                itemMesh.setVerticesData(buffer_1.VertexBuffer.MatricesIndicesKind, weaponMI, false);
                itemMesh.setVerticesData(buffer_1.VertexBuffer.MatricesWeightsKind, weaponMW, false);
                // cleanup
                rawMesh.dispose();
                itemMesh.setEnabled(false);
                // save for later use
                entityData.items.set(itemKey, itemMesh);
            }
        });
    }
    prepareEmbeddedItemForVat(entityData, itemKey) {
        return __awaiter(this, void 0, void 0, function* () {
            // if already prepared, stop
            if (entityData.items.has(itemKey)) {
                return false;
            }
            // clone raw mesh
            let key = entityData.name;
            let item = this._game.getGameData("item", itemKey);
            let rawMesh = this._game._loadedAssets["RACE_" + key].meshes[0].clone("embedded_vat_" + itemKey);
            // find raw mesh
            //console.log(rawMesh.id, rawMesh.getChildMeshes());
            rawMesh.getChildMeshes(false).forEach((element) => {
                var n = element.id.lastIndexOf(".");
                var result = element.id.substring(n + 1);
                if (!item.equippable.mesh.includes(result)) {
                    element.dispose();
                }
                else {
                    //console.log("FOUND MESH", element.id);
                }
            });
            let itemMesh = (0, MeshHelper_1.mergeMesh)(rawMesh, itemKey);
            if (itemMesh) {
                // update material
                if (item.material) {
                    VatController.loadItemMaterial(this._game.scene, itemMesh, item.material);
                }
                // attach to VAT
                itemMesh.skeleton = entityData.skeleton;
                itemMesh.registerInstancedBuffer("bakedVertexAnimationSettingsInstanced", 4);
                itemMesh.instancedBuffers.bakedVertexAnimationSettingsInstanced = new math_vector_1.Vector4(0, 0, 0, 0);
                itemMesh.bakedVertexAnimationManager = entityData.vat;
                entityData.items.set(itemKey, itemMesh);
                itemMesh.setEnabled(false);
                rawMesh.dispose();
            }
        });
    }
    prepareMaterial(cloneMesh, raceKey, materialIndex) {
        var _a;
        // get race
        let race = this._game.getGameData("race", raceKey);
        // remove any existing material
        const selectedMaterial = (_a = cloneMesh.material) !== null && _a !== void 0 ? _a : false;
        if (selectedMaterial) {
            selectedMaterial.dispose();
        }
        let materialKey = race.materials[materialIndex].material;
        let alreadyExistMaterial = this._game.scene.getMaterialByName(materialKey);
        if (alreadyExistMaterial) {
            cloneMesh.material = alreadyExistMaterial;
        }
        else {
            // create material as it does not exists
            let mat = new pbrCustomMaterial_1.PBRCustomMaterial(materialKey);
            mat.albedoTexture = new texture_1.Texture("./models/materials/" + materialKey, this._game.scene, {
                invertY: false,
            });
            mat.reflectionColor = new math_color_1.Color3(0, 0, 0);
            mat.reflectivityColor = new math_color_1.Color3(0, 0, 0);
            mat.backFaceCulling = false;
            mat.freeze();
            // assign to mesh
            cloneMesh.material = mat;
        }
    }
    static loadItemMaterial(scene, cloneMesh, materialName) {
        var _a;
        // remove any existing material
        const existing = (_a = cloneMesh.material) !== null && _a !== void 0 ? _a : false;
        if (existing) {
            existing.dispose();
        }
        //
        let alreadyExistMaterial = scene.getMaterialByName(materialName);
        if (alreadyExistMaterial) {
            cloneMesh.material = alreadyExistMaterial;
        }
        else {
            // create material as it does not exists
            let mat = new pbrCustomMaterial_1.PBRCustomMaterial(materialName + "_custom");
            mat.albedoTexture = new texture_1.Texture("./models/materials/" + materialName, scene, {
                invertY: false,
            });
            mat.reflectionColor = new math_color_1.Color3(0, 0, 0);
            mat.reflectivityColor = new math_color_1.Color3(0, 0, 0);
            mat.backFaceCulling = false;
            mat.freeze();
            // assign to mesh
            cloneMesh.material = mat;
        }
    }
    bakeTextureAnimation(key, merged) {
        return __awaiter(this, void 0, void 0, function* () {
            const b = new vertexAnimationBaker_1.VertexAnimationBaker(this._game.scene, merged);
            const bufferFromMesh = yield (0, VatHelper_1.bakeVertexData)(merged, this._entityData[key].selectedAnimationGroups);
            let vertexDataJson = b.serializeBakedVertexDataToJSON(bufferFromMesh);
            new JavascriptDataDownloader(vertexDataJson).download("text/json", key + ".json");
        });
    }
    bakeTextureAnimationRealtime(key, merged) {
        return __awaiter(this, void 0, void 0, function* () {
            const b = new vertexAnimationBaker_1.VertexAnimationBaker(this._game.scene, merged);
            const bufferFromMesh = yield (0, VatHelper_1.bakeVertexData)(merged, this._entityData[key].selectedAnimationGroups);
            const buffer = bufferFromMesh;
            this._entityData[key].vat.texture = b.textureFromBakedVertexData(buffer);
        });
    }
    loadBakedAnimation(key, merged) {
        return __awaiter(this, void 0, void 0, function* () {
            const b = new vertexAnimationBaker_1.VertexAnimationBaker(this._game.scene, merged);
            const req = yield fetch("./models/races/vat/" + key + ".json");
            const json = yield req.json();
            let bufferFromMesh = yield b.loadBakedVertexDataFromJSON(json);
            this._entityData.get(key).vat.texture = b.textureFromBakedVertexData(bufferFromMesh);
        });
    }
    // todo: there must a better way to do this, it's so ugly
    getAnimationGroups(animationGroups, raceAnimations) {
        let anims = [];
        for (let i in raceAnimations) {
            let animationGroup = raceAnimations[i];
            let anim = animationGroups.filter((ag) => animationGroup.name === ag.name);
            if (anim && anim[0]) {
                anims.push(anim[0]);
            }
        }
        return anims;
    }
    process(delta) {
        this._entityData.forEach((entityData) => {
            entityData.vat.time += this._game.scene.getEngine().getDeltaTime() / 1000.0;
        });
    }
}
exports.VatController = VatController;
