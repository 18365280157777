"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSidebarWidth = exports.createButton = exports.applyTheme = exports.generatePanel = exports.getPadding = exports.getBg = exports.applyFont = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const button_1 = require("@babylonjs/gui/2D/controls/button");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const createButton = function (name, text, width, height, icon, size = "md") {
    let result = new button_1.Button(name);
    result.width = width;
    result.height = height;
    applyTheme(result);
    if (icon) {
        let image = new image_1.Image("img" + name, "images/icons/" + icon + ".png");
        image.stretch = image_1.Image.STRETCH_UNIFORM;
        result.addControl(image);
    }
    let textBlock = new textBlock_1.TextBlock(name + "_text", text);
    textBlock.width = 1;
    textBlock.textWrapping = true;
    textBlock.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
    textBlock.color = "white";
    textBlock.fontWeight = "bold";
    //textBlock.fontFamily = getFont();
    if (size === "md") {
        textBlock.fontSize = "14px;";
    }
    else if (size === "sm") {
        textBlock.fontSize = "12px;";
    }
    else if (size === "xs") {
        textBlock.fontSize = "10px;";
    }
    else {
        textBlock.fontSize = "14px;";
    }
    result.addControl(textBlock);
    return result;
};
exports.createButton = createButton;
const generatePanel = function (panelName = "Default Name", width = "300px;", height = "400px", top = "0px", left = "0px") {
    let panel = new rectangle_1.Rectangle("panel-" + panelName);
    panel.top = top;
    panel.left = left;
    panel.width = width;
    panel.height = height;
    //panel.fontFamily = getFont();
    applyTheme(panel);
    return panel;
};
exports.generatePanel = generatePanel;
const applyTheme = function (panel) {
    panel.thickness = 3;
    panel.cornerRadius = 2;
    panel.background = getBg();
    panel.color = "rgba(0,0,0,1)";
    panel.fontFamily = getFont();
};
exports.applyTheme = applyTheme;
const applyFont = function (p, size = "12px") {
    //p.fontFamily = getFont();
    p.fontSize = size;
};
exports.applyFont = applyFont;
const getFont = function () {
    //return "inherit";
};
const getBg = function () {
    return "rgba(0,0,0,.8)";
};
exports.getBg = getBg;
const getPadding = function (multiplier = 1) {
    return multiplier * 5;
};
exports.getPadding = getPadding;
const getSidebarWidth = function () {
    return 320;
};
exports.getSidebarWidth = getSidebarWidth;
