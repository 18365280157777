"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const button_1 = require("@babylonjs/gui/2D/controls/button");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Theme_1 = require("../Theme");
class Panel {
    constructor(_UI, _currentPlayer, options) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        // drag stuff
        this._isPointerDown = false;
        this._isDragging = false;
        //
        this._UI = _UI;
        this._game = _UI._game;
        this._playerUI = _UI._playerUI;
        this._UITooltip = _UI._UITooltip;
        this._room = _UI._room;
        this._scene = _UI._scene;
        this._currentPlayer = _currentPlayer;
        this._loadedAssets = _UI._loadedAssets;
        this._engine = _UI._engine;
        // set defaults
        this._options = {
            name: (_a = options.name) !== null && _a !== void 0 ? _a : "Panel Name",
            horizontal_position: (_b = options.horizontal_position) !== null && _b !== void 0 ? _b : control_1.Control.HORIZONTAL_ALIGNMENT_CENTER,
            vertical_position: (_c = options.vertical_position) !== null && _c !== void 0 ? _c : control_1.Control.VERTICAL_ALIGNMENT_CENTER,
            width: (_d = options.width) !== null && _d !== void 0 ? _d : 1,
            height: (_e = options.height) !== null && _e !== void 0 ? _e : 1,
            top: (_f = options.top) !== null && _f !== void 0 ? _f : "0px",
            left: (_g = options.left) !== null && _g !== void 0 ? _g : "0px",
            stayOpen: (_h = options.stayOpen) !== null && _h !== void 0 ? _h : false,
        };
        //
        this._create(this._options);
        // some ui must be constantly refreshed as things change
        this._scene.registerBeforeRender(() => {
            // refresh
            this.update();
        });
    }
    // create panel
    _create(options) {
        let panel = new rectangle_1.Rectangle("panel-" + options.name);
        panel.top = options.top;
        panel.left = options.left;
        panel.width = options.width;
        panel.height = options.height;
        panel.verticalAlignment = options.horizontal_position;
        panel.horizontalAlignment = options.vertical_position;
        panel.thickness = options.thickness;
        panel.cornerRadius = options.cornerRadius;
        panel.background = options.background;
        panel.color = options.color;
        panel.isPointerBlocker = true;
        panel.isVisible = false;
        panel.zIndex = 10;
        (0, Theme_1.applyTheme)(panel);
        this._playerUI.addControl(panel);
        this._panel = panel;
        this._createHeader();
        this._createContentPanel();
    }
    // create panel header
    _createContentPanel() {
        const panelContent = new rectangle_1.Rectangle("panelContent");
        panelContent.top = "30px;";
        panelContent.left = 0;
        panelContent.width = 1;
        panelContent.height = 0.91;
        panelContent.thickness = 0;
        panelContent.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        panelContent.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        this._panel.addControl(panelContent);
        this._panelContent = panelContent;
    }
    // create panel header
    _createHeader() {
        const panelHeader = new rectangle_1.Rectangle("panelHeader");
        panelHeader.top = 0;
        panelHeader.left = 0;
        panelHeader.width = 1;
        panelHeader.height = "30px";
        panelHeader.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        panelHeader.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        panelHeader.color = "rgba(0,0,0,1)";
        panelHeader.thickness = 0;
        panelHeader.fontFamily = "gamefont";
        this._panel.addControl(panelHeader);
        // header title
        var panelTitle = new textBlock_1.TextBlock("panelTitle");
        panelTitle.text = this._options.name;
        panelTitle.color = "#FFFFFF";
        panelTitle.top = "0px";
        panelTitle.left = "5px";
        panelTitle.fontSize = "22px";
        panelTitle.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        panelTitle.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        panelTitle.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        panelTitle.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        panelHeader.addControl(panelTitle);
        this._panelTitle = panelTitle;
        // close button
        const mainPanelClose = button_1.Button.CreateSimpleButton("mainPanelClose", "X");
        mainPanelClose.width = "20px";
        mainPanelClose.height = "20px";
        mainPanelClose.color = "white";
        mainPanelClose.top = "5px";
        mainPanelClose.left = "-5px";
        mainPanelClose.thickness = 1;
        mainPanelClose.background = "black";
        mainPanelClose.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        mainPanelClose.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        panelHeader.addControl(mainPanelClose);
        mainPanelClose.onPointerDownObservable.add(() => {
            this.close();
        });
        // drag and drop events
        panelHeader.onPointerDownObservable.add((e) => {
            this._UI.startDragging(this._panel);
            this._panel.isPointerBlocker = false;
        });
        panelHeader.onPointerUpObservable.add((e) => {
            this._UI.stopDragging();
        });
    }
    // open panel
    open() {
        const visible = this._panel.isVisible;
        // close all panels
        if (!this._options.stayOpen) {
            this._UI.panelAbilities._panel.isVisible = false;
            this._UI.panelCharacter._panel.isVisible = false;
            this._UI.panelHelp._panel.isVisible = false;
            this._UI.panelQuests._panel.isVisible = false;
        }
        // if already open, close panel
        if (visible) {
            this._panel.isVisible = false;
            this._game.gamescene._sound.play("SOUND_dialog_close");
        }
        else {
            this._panel.isVisible = true;
            this._game.gamescene._sound.play("SOUND_dialog_open");
        }
    }
    // close panel
    close() {
        this._panel.isVisible = false;
        if (this.vendor) {
            this.vendor.sellingModeOff();
        }
        this._game.gamescene._sound.play("SOUND_dialog_close");
    }
    isOpen() {
        return this._panel.isVisible;
    }
    // update panel
    update() { }
    // refresh panel
    refresh() { }
}
exports.Panel = Panel;
