"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculationTypes = exports.Speed = exports.PlayerKeys = exports.EquippableType = exports.ItemRarity = exports.PlayerSlots = exports.ItemClass = exports.AbilityElement = exports.AbilityType = exports.QuestStatus = exports.QuestObjectives = exports.QuestObjective = exports.ServerMsg = exports.AI_STATE = exports.EntityState = void 0;
var EntityState;
(function (EntityState) {
    EntityState[EntityState["IDLE"] = 0] = "IDLE";
    EntityState[EntityState["WALKING"] = 1] = "WALKING";
    EntityState[EntityState["RUNNING"] = 2] = "RUNNING";
    EntityState[EntityState["TAKING_DAMAGE"] = 3] = "TAKING_DAMAGE";
    EntityState[EntityState["DEAD"] = 4] = "DEAD";
    EntityState[EntityState["PICKUP"] = 5] = "PICKUP";
    EntityState[EntityState["SPELL_CASTING"] = 6] = "SPELL_CASTING";
    EntityState[EntityState["SPELL_CAST"] = 7] = "SPELL_CAST";
    EntityState[EntityState["UNARMED"] = 8] = "UNARMED";
    EntityState[EntityState["ATTACK_01"] = 9] = "ATTACK_01";
    EntityState[EntityState["ATTACK_02"] = 10] = "ATTACK_02";
})(EntityState || (exports.EntityState = EntityState = {}));
var AI_STATE;
(function (AI_STATE) {
    AI_STATE[AI_STATE["IDLE"] = 0] = "IDLE";
    AI_STATE[AI_STATE["WANDER"] = 1] = "WANDER";
    AI_STATE[AI_STATE["SEEKING"] = 2] = "SEEKING";
    AI_STATE[AI_STATE["ATTACKING"] = 3] = "ATTACKING";
})(AI_STATE || (exports.AI_STATE = AI_STATE = {}));
var ServerMsg;
(function (ServerMsg) {
    ServerMsg[ServerMsg["PING"] = 1] = "PING";
    ServerMsg[ServerMsg["PONG"] = 2] = "PONG";
    ServerMsg[ServerMsg["CHAT_MESSAGE"] = 3] = "CHAT_MESSAGE";
    ServerMsg[ServerMsg["SERVER_MESSAGE"] = 4] = "SERVER_MESSAGE";
    ServerMsg[ServerMsg["PLAYER_SEND_MESSAGE"] = 5] = "PLAYER_SEND_MESSAGE";
    ServerMsg[ServerMsg["PLAYER_RESET_POSITION"] = 6] = "PLAYER_RESET_POSITION";
    ServerMsg[ServerMsg["PLAYER_RESSURECT"] = 7] = "PLAYER_RESSURECT";
    ServerMsg[ServerMsg["PLAYER_LEARN_SKILL"] = 8] = "PLAYER_LEARN_SKILL";
    ServerMsg[ServerMsg["PLAYER_ADD_STAT_POINT"] = 9] = "PLAYER_ADD_STAT_POINT";
    ServerMsg[ServerMsg["PLAYER_MOVE"] = 10] = "PLAYER_MOVE";
    ServerMsg[ServerMsg["PLAYER_MOVE_TO"] = 11] = "PLAYER_MOVE_TO";
    ServerMsg[ServerMsg["PLAYER_PICKUP"] = 12] = "PLAYER_PICKUP";
    ServerMsg[ServerMsg["PLAYER_DROP_ITEM"] = 13] = "PLAYER_DROP_ITEM";
    ServerMsg[ServerMsg["PLAYER_USE_ITEM"] = 14] = "PLAYER_USE_ITEM";
    ServerMsg[ServerMsg["PLAYER_UNEQUIP_ITEM"] = 15] = "PLAYER_UNEQUIP_ITEM";
    ServerMsg[ServerMsg["PLAYER_HOTBAR_ACTIVATED"] = 16] = "PLAYER_HOTBAR_ACTIVATED";
    ServerMsg[ServerMsg["PLAYER_ABILITY_CAST"] = 17] = "PLAYER_ABILITY_CAST";
    ServerMsg[ServerMsg["PLAYER_AUTO_ATTACK"] = 18] = "PLAYER_AUTO_ATTACK";
    ServerMsg[ServerMsg["PLAYER_CASTING_START"] = 19] = "PLAYER_CASTING_START";
    ServerMsg[ServerMsg["PLAYER_CASTING_CANCEL"] = 20] = "PLAYER_CASTING_CANCEL";
    ServerMsg[ServerMsg["PLAYER_TELEPORT"] = 21] = "PLAYER_TELEPORT";
    ServerMsg[ServerMsg["PLAYER_QUEST_UPDATE"] = 22] = "PLAYER_QUEST_UPDATE";
    ServerMsg[ServerMsg["PLAYER_BUY_ITEM"] = 23] = "PLAYER_BUY_ITEM";
    ServerMsg[ServerMsg["PLAYER_SELL_ITEM"] = 24] = "PLAYER_SELL_ITEM";
    ServerMsg[ServerMsg["DEBUG_REMOVE_ENTITIES"] = 25] = "DEBUG_REMOVE_ENTITIES";
    ServerMsg[ServerMsg["DEBUG_INCREASE_ENTITIES"] = 26] = "DEBUG_INCREASE_ENTITIES";
    ServerMsg[ServerMsg["DEBUG_DECREASE_ENTITIES"] = 27] = "DEBUG_DECREASE_ENTITIES";
    ServerMsg[ServerMsg["DEBUG_BOTS"] = 28] = "DEBUG_BOTS";
})(ServerMsg || (exports.ServerMsg = ServerMsg = {}));
var QuestObjective;
(function (QuestObjective) {
    QuestObjective[QuestObjective["KILL_AMOUNT"] = 1] = "KILL_AMOUNT";
    QuestObjective[QuestObjective["TALK_TO"] = 2] = "TALK_TO";
})(QuestObjective || (exports.QuestObjective = QuestObjective = {}));
exports.QuestObjectives = {
    [QuestObjective.KILL_AMOUNT]: "Kill @TargetName @KillCompleted/@KillRequired",
    [QuestObjective.TALK_TO]: "Talk to @TargetName",
};
var QuestStatus;
(function (QuestStatus) {
    QuestStatus[QuestStatus["NOT_ACCEPTED"] = 0] = "NOT_ACCEPTED";
    QuestStatus[QuestStatus["ACCEPTED"] = 1] = "ACCEPTED";
    QuestStatus[QuestStatus["OBJECTIVE_UPDATE"] = 2] = "OBJECTIVE_UPDATE";
    QuestStatus[QuestStatus["READY_TO_COMPLETE"] = 3] = "READY_TO_COMPLETE";
    QuestStatus[QuestStatus["COMPLETED"] = 4] = "COMPLETED";
})(QuestStatus || (exports.QuestStatus = QuestStatus = {}));
//////////////////////////////////////////////////////////////
///////////////// ABILITIES /////////////////////
//////////////////////////////////////////////////////////////
var AbilityType;
(function (AbilityType) {
    AbilityType[AbilityType["PHYSICAL"] = 0] = "PHYSICAL";
    AbilityType[AbilityType["MENTAL"] = 1] = "MENTAL";
})(AbilityType || (exports.AbilityType = AbilityType = {}));
var AbilityElement;
(function (AbilityElement) {
    AbilityElement[AbilityElement["FIRE"] = 0] = "FIRE";
    AbilityElement[AbilityElement["WATER"] = 1] = "WATER";
    AbilityElement[AbilityElement["EARTH"] = 2] = "EARTH";
    AbilityElement[AbilityElement["LIGHT"] = 3] = "LIGHT";
})(AbilityElement || (exports.AbilityElement = AbilityElement = {}));
//////////////////////////////////////////////////////////////
///////////////// ITEMS /////////////////////
//////////////////////////////////////////////////////////////
var ItemClass;
(function (ItemClass) {
    ItemClass[ItemClass["WEAPON"] = 1] = "WEAPON";
    ItemClass[ItemClass["ARMOR"] = 2] = "ARMOR";
    ItemClass[ItemClass["CONSUMABLE"] = 3] = "CONSUMABLE";
    ItemClass[ItemClass["QUEST"] = 4] = "QUEST";
    ItemClass[ItemClass["OTHER"] = 5] = "OTHER";
})(ItemClass || (exports.ItemClass = ItemClass = {}));
var PlayerSlots;
(function (PlayerSlots) {
    PlayerSlots[PlayerSlots["HEAD"] = 1] = "HEAD";
    PlayerSlots[PlayerSlots["AMULET"] = 2] = "AMULET";
    PlayerSlots[PlayerSlots["CHEST"] = 3] = "CHEST";
    PlayerSlots[PlayerSlots["PANTS"] = 4] = "PANTS";
    PlayerSlots[PlayerSlots["SHOES"] = 5] = "SHOES";
    PlayerSlots[PlayerSlots["WEAPON"] = 6] = "WEAPON";
    PlayerSlots[PlayerSlots["OFF_HAND"] = 7] = "OFF_HAND";
    PlayerSlots[PlayerSlots["RING_1"] = 8] = "RING_1";
    PlayerSlots[PlayerSlots["RING_2"] = 9] = "RING_2";
    PlayerSlots[PlayerSlots["BACK"] = 10] = "BACK";
})(PlayerSlots || (exports.PlayerSlots = PlayerSlots = {}));
var ItemRarity;
(function (ItemRarity) {
    ItemRarity[ItemRarity["NORMAL"] = 0] = "NORMAL";
    ItemRarity[ItemRarity["RARE"] = 1] = "RARE";
    ItemRarity[ItemRarity["LEGENDARY"] = 2] = "LEGENDARY";
})(ItemRarity || (exports.ItemRarity = ItemRarity = {}));
var EquippableType;
(function (EquippableType) {
    EquippableType[EquippableType["DYNAMIC"] = 0] = "DYNAMIC";
    EquippableType[EquippableType["EMBEDDED"] = 1] = "EMBEDDED";
    EquippableType[EquippableType["NOT_VISIBLE"] = 2] = "NOT_VISIBLE";
})(EquippableType || (exports.EquippableType = EquippableType = {}));
var PlayerKeys;
(function (PlayerKeys) {
    PlayerKeys["STRENGTH"] = "strength";
    PlayerKeys["ENDURANCE"] = "endurance";
    PlayerKeys["AGILITY"] = "agility";
    PlayerKeys["WISDOM"] = "wisdom";
    PlayerKeys["INTELLIGENCE"] = "inteligence";
    PlayerKeys["AC"] = "ac";
    PlayerKeys["LEVEL"] = "level";
    PlayerKeys["HEALTH"] = "health";
    PlayerKeys["MANA"] = "mana";
})(PlayerKeys || (exports.PlayerKeys = PlayerKeys = {}));
//////////////////////////////////////////////////////////////
///////////////// UTILS /////////////////////
//////////////////////////////////////////////////////////////
var Speed;
(function (Speed) {
    Speed[Speed["VERY_SLOW"] = 0.2] = "VERY_SLOW";
    Speed[Speed["SLOW"] = 0.35] = "SLOW";
    Speed[Speed["MEDIUM"] = 0.65] = "MEDIUM";
    Speed[Speed["QUICK"] = 0.8] = "QUICK";
    Speed[Speed["VERY_QUICK"] = 1] = "VERY_QUICK";
})(Speed || (exports.Speed = Speed = {}));
var CalculationTypes;
(function (CalculationTypes) {
    CalculationTypes[CalculationTypes["ADD"] = 1] = "ADD";
    CalculationTypes[CalculationTypes["REMOVE"] = 2] = "REMOVE";
    CalculationTypes[CalculationTypes["MULTIPLY"] = 3] = "MULTIPLY";
})(CalculationTypes || (exports.CalculationTypes = CalculationTypes = {}));
