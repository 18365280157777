"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Item = void 0;
const actionManager_1 = require("@babylonjs/core/Actions/actionManager");
const directActions_1 = require("@babylonjs/core/Actions/directActions");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
const Utils_1 = require("../../shared/Utils");
const transformNode_1 = require("@babylonjs/core/Meshes/transformNode");
const EntityNamePlate_1 = require("./Entity/EntityNamePlate");
class Item extends transformNode_1.TransformNode {
    constructor(name, scene, entity, room, ui, game) {
        super(name, scene);
        this.type = "";
        this.name = "";
        //
        this.scale = 1;
        this.health = 1;
        // flags
        this.blocked = false; // if true, player will not moved
        // setup class variables
        this._scene = scene;
        this._game = game;
        this._room = room;
        this._ui = ui;
        // add entity data
        this.entity = entity;
        //
        this.nameplateController = new EntityNamePlate_1.EntityNamePlate(this);
        // update player data from server data
        let item = this._game.getGameData("item", entity.key);
        Object.assign(this, item);
        // update player data from server data
        Object.assign(this, this.entity);
        //
        this.name = item.title;
        this.spawnInfo = {
            key: this.key,
        };
        // set parent metadata
        this.metadata = {
            sessionId: entity.sessionId,
            type: "item",
            name: entity.name,
        };
        // spawn item
        this.spawn(entity);
    }
    spawn(entity_1) {
        return __awaiter(this, arguments, void 0, function* (entity, mode = "instance") {
            /*
            // load item mesh
            if (mode === "instance") {
                // instance
                this.mesh = this._game._loadedAssets["ROOT_ITEM_" + entity.key].createInstance("TEST_" + entity.sessionId);
                this._game._loadedAssets["ROOT_ITEM_" + entity.key].setParent(null);
            } else if (mode === "clone") {
                // clone
                if (this._game._loadedAssets["ROOT_ITEM_" + entity.key]) {
                    this.mesh = this._game._loadedAssets["ROOT_ITEM_" + entity.key].clone("TEST_" + entity.sessionId);
                } else {
                    console.error("Could not find key: ROOT_ITEM_" + entity.key, this._game._loadedAssets);
                }
    
                // import normal
            } else {
                const result = await this._game._loadedAssets["ITEM_" + entity.key].instantiateModelsToScene((name) => "instance_" + this.entity.sessionId, false, {
                    doNotInstantiate: false,
                });
                this.mesh = result.rootNodes[0] as Mesh;
            }*/
            let key = "ROOT_ITEM_" + entity.key;
            // load mesh if not already loaded
            if (!this._game._loadedAssets[key]) {
                yield this._game._assetsCtrl.prepareItem(entity.key);
            }
            //
            this.mesh = this._game._loadedAssets[key].createInstance("GROUND_" + entity.sessionId);
            // set initial player scale & rotation
            this.mesh.parent = this;
            // set collision mesh
            this.mesh.name = entity.key + "_box";
            this.mesh.isPickable = true;
            this.mesh.isVisible = true;
            this.mesh.checkCollisions = false;
            this.mesh.showBoundingBox = false;
            this.mesh.receiveShadows = false;
            // offset mesh from the ground
            let meshSize = this.mesh.getBoundingInfo().boundingBox.extendSize;
            this.mesh.position.y += meshSize.y * this.meshData.scale;
            this.mesh.rotationQuaternion = null; // You cannot use a rotationQuaternion followed by a rotation on the same mesh. Once a rotationQuaternion is applied any subsequent use of rotation will produce the wrong orientation, unless the rotationQuaternion is first set to null.
            this.mesh.rotation = new math_vector_1.Vector3(0, (0, Utils_1.randomNumberInRange)(0, 360), 0);
            this.mesh.scaling = new math_vector_1.Vector3(this.meshData.scale, this.meshData.scale, this.meshData.scale);
            // set mesh metadata
            this.mesh.metadata = {
                sessionId: this.entity.sessionId,
                type: "item",
                key: this.key,
                name: this.entity.name,
            };
            let shadowMesh = this._game._loadedAssets["DYNAMIC_shadow_01"].createInstance("shadow_" + this.sessionId);
            shadowMesh.parent = this;
            shadowMesh.isPickable = false;
            shadowMesh.checkCollisions = false;
            shadowMesh.doNotSyncBoundingInfo = true;
            shadowMesh.position = new math_vector_1.Vector3(0, 0.04, 0);
            this.fakeShadow = shadowMesh;
            // add nameplate
            //this.nameplate = this.nameplateController.addNamePlate();
            // set position
            this.setPosition();
            //////////////////////////////////////////////
            // entity network event
            // colyseus automatically sends entity updates, so let's listen to those changes
            this.entity.onChange(() => {
                // update player data from server data
                Object.assign(this, this.entity);
                // set item position
                this.setPosition();
            });
            ///
            // start action manager
            this.mesh.actionManager = new actionManager_1.ActionManager(this._scene);
            // register hover over player
            this.mesh.actionManager.registerAction(new directActions_1.ExecuteCodeAction(actionManager_1.ActionManager.OnPointerOverTrigger, (ev) => {
                let mesh = ev.meshUnderPointer;
                if (mesh) {
                    mesh.overlayColor = new math_color_1.Color3(1, 1, 1);
                    mesh.overlayAlpha = 0.3;
                    mesh.renderOverlay = true;
                }
                if (this.mesh.actionManager) {
                    this.mesh.actionManager.hoverCursor = this._ui._Cursor.get("hover");
                }
            }));
            // register hover out player
            this.mesh.actionManager.registerAction(new directActions_1.ExecuteCodeAction(actionManager_1.ActionManager.OnPointerOutTrigger, (ev) => {
                let mesh = ev.meshUnderPointer;
                if (mesh) {
                    mesh.renderOverlay = false;
                }
                if (this.mesh.actionManager) {
                    this.mesh.actionManager.hoverCursor = this._ui._Cursor.get();
                }
            }));
            //////////////////////////////////////////////////////////////////////////
            // misc
            //this.characterLabel = this._ui.createItemLabel(this);
        });
    }
    lod(_currentPlayer) {
        if (!this.mesh) {
            return false;
        }
        // only enable if close enough to local player
        let entityPos = this.getPosition();
        let playerPos = _currentPlayer.getPosition();
        let distanceFromPlayer = math_vector_1.Vector3.Distance(playerPos, entityPos);
        if (distanceFromPlayer < this._game.config.PLAYER_VIEW_DISTANCE) {
            this.setEnabled(true);
            this.unfreezeWorldMatrix();
        }
        else {
            // hide everything
            this.setEnabled(false);
            this.freezeWorldMatrix();
        }
    }
    update(delta) { }
    updateServerRate(delta) { }
    updateSlowRate(delta) { }
    setPosition() {
        this.position = this.getPosition();
    }
    getPosition() {
        return new math_vector_1.Vector3(this.x, this.y, this.z);
    }
    remove() {
        if (this.nameplate) {
            this.nameplate.dispose();
        }
        if (this.fakeShadow) {
            this.fakeShadow.dispose();
        }
        if (this.mesh) {
            this.mesh.dispose();
        }
    }
}
exports.Item = Item;
