"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Quests = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const Panel_1 = require("./Panel");
const scrollViewer_1 = require("@babylonjs/gui/2D/controls/scrollViewers/scrollViewer");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const types_1 = require("../../../../shared/types");
const QuestsHelper_1 = require("../../../../shared/Class/QuestsHelper");
class Panel_Quests extends Panel_1.Panel {
    constructor(_UI, _currentPlayer, options) {
        super(_UI, _currentPlayer, options);
        // create content
        this.createContent();
        // dynamic events
        let entity = this._currentPlayer.entity;
        entity.player_data.quests.onAdd((item, sessionId) => {
            this.refresh();
            // todo: could be a performance issue here?
            // orion to keep an eye on this one
            item.onChange((item, sessionId) => {
                this.refresh();
            });
            item.onRemove((item, sessionId) => {
                this.refresh();
            });
        });
    }
    // open panel
    open() {
        super.open();
        this.refresh();
    }
    refresh() {
        this.createContent();
    }
    replaceKeywords(text, quest, pQuest, location) {
        text = text.replace("@KillRequired", quest.quantity);
        text = text.replace("@TargetName", QuestsHelper_1.QuestsHelper.findQuestTargetName(location, quest.spawn_key, quest.quantity));
        text = text.replace("@KillCompleted", pQuest.qty);
        return text;
    }
    // create panel
    createContent() {
        var _a;
        // if already exists
        this._panelContent.children.forEach((el) => {
            el.dispose();
        });
        // add scrollable container
        const scrollViewer = new scrollViewer_1.ScrollViewer("scrollViewer");
        scrollViewer.width = 0.99;
        scrollViewer.height = 1;
        scrollViewer.top = 0;
        scrollViewer.thickness = 0;
        scrollViewer.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        scrollViewer.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        this._panelContent.addControl(scrollViewer);
        // add stack panel
        const stackPanel = new stackPanel_1.StackPanel("stackPanel");
        stackPanel.width = 1;
        stackPanel.height = 1;
        stackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        stackPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        stackPanel.paddingTop = "5px;";
        stackPanel.spacing = 5;
        scrollViewer.addControl(stackPanel);
        // add quests
        let quests = (_a = this._currentPlayer.entity.player_data.quests) !== null && _a !== void 0 ? _a : [];
        if (quests.size > 0) {
            quests.forEach((q) => {
                let quest = this._game.getGameData("quest", q.key);
                let location = this._game.getGameData("location", quest.location);
                let short_objective = types_1.QuestObjectives[quest.type];
                short_objective = this.replaceKeywords(short_objective, quest, q, location);
                let questPanel = new rectangle_1.Rectangle("questPanel");
                questPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                questPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
                questPanel.top = "0px";
                questPanel.left = "0px;";
                questPanel.width = 1;
                questPanel.height = "40px";
                questPanel.thickness = 1;
                questPanel.setPadding(0);
                questPanel.background = "black";
                questPanel.color = "gray";
                stackPanel.addControl(questPanel);
                var questTitle = new textBlock_1.TextBlock("questTitle");
                questTitle.top = "4px";
                questTitle.paddingLeft = "5px";
                questTitle.text = quest.title;
                questTitle.textHorizontalAlignment = 0;
                questTitle.fontSize = "14px";
                questTitle.color = "white";
                questTitle.fontWeight = "bold";
                questTitle.textWrapping = 1 /* TextWrapping.WordWrap */;
                questTitle.resizeToFit = true;
                questTitle.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                questTitle.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
                questPanel.addControl(questTitle);
                var questObjective = new textBlock_1.TextBlock("questObjective");
                questObjective.top = "20px";
                questObjective.paddingLeft = "5px";
                questObjective.text = short_objective;
                questObjective.textHorizontalAlignment = 0;
                questObjective.fontSize = "12px";
                questObjective.color = "orange";
                questObjective.textWrapping = 1 /* TextWrapping.WordWrap */;
                questObjective.resizeToFit = true;
                questObjective.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                questObjective.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
                questPanel.addControl(questObjective);
            });
        }
        else {
            var noQuests = new textBlock_1.TextBlock("noQuests");
            noQuests.text = "You have no quests. Explore the world & talk to people to discover new quests.";
            noQuests.fontSize = "14px";
            noQuests.color = "white";
            noQuests.textWrapping = 1 /* TextWrapping.WordWrap */;
            noQuests.resizeToFit = true;
            noQuests.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            noQuests.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            noQuests.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            stackPanel.addControl(noQuests);
        }
    }
}
exports.Panel_Quests = Panel_Quests;
