"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityActions = void 0;
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const particleSystem_1 = require("@babylonjs/core/Particles/particleSystem");
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const tools_1 = require("@babylonjs/core/Misc/tools");
const nanoid_1 = require("nanoid");
const types_1 = require("../../../shared/types");
class EntityActions {
    constructor(scene, _loadedAssets, entities, gamescene) {
        this.projectiles = new Map();
        this.colors = {
            white: [math_color_1.Color3.FromInts(255, 255, 255), math_color_1.Color3.FromInts(240, 240, 240)],
            green: [math_color_1.Color3.FromInts(64, 141, 33), math_color_1.Color3.FromInts(146, 245, 107)],
            orange: [math_color_1.Color3.FromInts(249, 115, 0), math_color_1.Color3.FromInts(222, 93, 54)],
            red: [math_color_1.Color3.FromInts(194, 24, 7), math_color_1.Color3.FromInts(249, 115, 0)],
            blood: [math_color_1.Color3.FromInts(155, 11, 0), math_color_1.Color3.FromInts(255, 24, 7)],
        };
        this._gamescene = gamescene;
        this._scene = scene;
        this._entities = entities;
        this._loadedAssets = _loadedAssets;
        this.particleTxt_01 = this._loadedAssets["TXT_particle_01"];
    }
    playSound() { }
    update() {
        this.projectiles.forEach((element, key) => {
            //
            let targetMesh = element.target.mesh;
            let end = element.target.getPosition();
            end.y = 1; // fix to hit in the center of body
            element.projectile.lookAt(end);
            element.projectile.rotate(new math_vector_1.Vector3(0, 1, 0), tools_1.Tools.ToRadians(180));
            // calculate next position
            var endVector = element.projectile.calcMovePOV(0, 0, 0.4).addInPlace(element.projectile.position);
            element.projectile.position = endVector;
            // if intersect with target mesh then display impact
            if (element.projectile.intersectsMesh(targetMesh, true)) {
                this.particule_impact(targetMesh, element.ability);
                element.projectile.dispose(true, true);
                element.particleSystem.dispose(true);
                element.particleSystemTrail.dispose(true);
                this.projectiles.delete(key);
            }
        });
    }
    findWeaponMesh(entity) {
        if (!entity.equipment) {
            return;
        }
        let found = false;
        entity.equipment.forEach((element) => {
            if (element.slot === types_1.PlayerSlots.WEAPON) {
                found = element.mesh;
            }
        });
        return found;
    }
    process(player, data, ability) {
        //
        let source = this._entities.get(data.fromId);
        let target = this._entities.get(data.targetId);
        // play sound
        setTimeout(() => {
            this._gamescene._sound.play("SOUND_" + ability.sound);
            // set effect
            if (ability.effect.type === "target") {
                //let mesh = this.findWeaponMesh(source);
                this.particule_damage(target.mesh, ability);
            }
            if (!source || !target)
                return;
            // set effect
            if (ability.effect.particule === "fireball") {
                this.particule_fireball(source, target, ability);
            }
            if (ability.effect.particule === "heal") {
                this.particule_heal(target, ability);
            }
        }, ability.soundDelay);
    }
    particule_damage(mesh, ability) {
        let color = ability.effect.color;
        //////////////////////////////////////////////
        // create a particle system
        var particleSystem = new particleSystem_1.ParticleSystem("particles", 100, this._scene);
        particleSystem.particleTexture = this._loadedAssets["TXT_particle_03"].clone();
        /*
        const emitter = new MeshParticleEmitter(mesh);
        emitter.useMeshNormalsForDirection = true;
        particleSystem.emitter = mesh;
        particleSystem.particleEmitterType = emitter; // the starting locatio
        
        
        var cylinderEmitter = particleSystem.createCylinderEmitter(4, 1, 1, 0);
        particleSystem.emitter = mesh; // the starting location
        particleSystem.particleEmitterType = cylinderEmitter;*/
        var cylinderEmitter = particleSystem.createSphereEmitter(1, 0);
        particleSystem.emitter = mesh; // the starting location
        particleSystem.particleEmitterType = cylinderEmitter;
        particleSystem.worldOffset = new math_vector_1.Vector3(0, 1, 0);
        // Colors of all particles
        particleSystem.color1 = math_color_1.Color4.FromColor3(this.colors[color][0]);
        particleSystem.color2 = math_color_1.Color4.FromColor3(this.colors[color][1]);
        // Size of each particle (random between...
        particleSystem.minSize = 0.1;
        particleSystem.maxSize = 0.2;
        // Life time of each particle (random between...
        particleSystem.minLifeTime = 1;
        particleSystem.maxLifeTime = 1;
        particleSystem.targetStopDuration = 1;
        // gravity
        particleSystem.gravity = new math_vector_1.Vector3(0, -1, 0);
        // Emission rate
        particleSystem.emitRate = 10;
        particleSystem.minEmitPower = 0.5;
        particleSystem.maxEmitPower = 1.2;
        particleSystem.updateSpeed = 0.058;
        /*
        particleSystem.minAngularSpeed = 0;
        particleSystem.maxAngularSpeed = Math.PI;
        particleSystem.minInitialRotation = 0;
        particleSystem.maxInitialRotation = Math.PI / 2;*/
        // Start the particle system
        particleSystem.start();
        //////////////////////////////////////////////
        setTimeout(() => {
            particleSystem.dispose(true);
        }, 1000);
        //
    }
    particule_impact(mesh, ability) {
        let color = ability.effect.color;
        this._gamescene._sound.play("SOUND_hit_a");
        //////////////////////////////////////////////
        // create a particle system
        var particleSystem = new particleSystem_1.ParticleSystem("particles", 1000, this._scene);
        particleSystem.particleTexture = this.particleTxt_01.clone();
        particleSystem.emitter = mesh; // the starting location
        // Colors of all particles
        particleSystem.color1 = math_color_1.Color4.FromColor3(this.colors[color][0]);
        particleSystem.color2 = math_color_1.Color4.FromColor3(this.colors[color][1]);
        particleSystem.colorDead = new math_color_1.Color4(0, 0, 0, 1);
        // Size of each particle (random between...
        particleSystem.minSize = 0.3;
        particleSystem.maxSize = 0.6;
        // Life time of each particle (random between...
        particleSystem.minLifeTime = 1;
        particleSystem.maxLifeTime = 1.25;
        particleSystem.targetStopDuration = 1.25;
        // Emission rate
        particleSystem.emitRate = 1000;
        particleSystem.createSphereEmitter(1);
        particleSystem.updateSpeed = 0.1;
        // Start the particle system
        particleSystem.start();
        //////////////////////////////////////////////
        setTimeout(() => {
            particleSystem.dispose(true);
        }, 1000);
        //
    }
    particule_heal(target, ability) {
        let color = ability.effect.color;
        // get mesh
        let mesh = target.mesh;
        //////////////////////////////////////////////
        // create a particle system
        var particleSystem = new particleSystem_1.ParticleSystem("particles", 1000, this._scene);
        particleSystem.particleTexture = this.particleTxt_01.clone();
        particleSystem.emitter = mesh; // the starting location
        // Colors of all particles
        particleSystem.color1 = math_color_1.Color4.FromColor3(this.colors[color][0]);
        particleSystem.color2 = math_color_1.Color4.FromColor3(this.colors[color][1]);
        particleSystem.colorDead = new math_color_1.Color4(0, 0, 0, 1);
        // Size of each particle (random between...
        particleSystem.minSize = 0.3;
        particleSystem.maxSize = 0.5;
        // Life time of each particle (random between...
        particleSystem.minLifeTime = 2;
        particleSystem.maxLifeTime = 2;
        particleSystem.targetStopDuration = 2;
        // Emission rate
        particleSystem.emitRate = 500;
        particleSystem.createCylinderEmitter(1);
        /**
         * Adds a new life time gradient
         * @param gradient defines the gradient to use (between 0 and 1)
         * @param factor defines the life time factor to affect to the specified gradient
         * @param factor2 defines an additional factor used to define a range ([factor, factor2]) with main value to pick the final value from
         * @returns the current particle system
         */
        particleSystem.addVelocityGradient(1, 1, 1); //start size at start of particle system
        particleSystem.updateSpeed = 0.1;
        // Start the particle system
        particleSystem.start();
        //////////////////////////////////////////////
        setTimeout(() => {
            particleSystem.dispose(true);
        }, 1000);
        //
    }
    particule_fireball(source, target, ability) {
        // get local position
        let color = ability.effect.color;
        let start = source.getPosition();
        let end = target.getPosition();
        // correct height
        start.y += 1;
        end.y += 1;
        // get mesh
        let mesh = target.mesh;
        // calculate angle
        var angle = Math.atan2(start.z - end.z, start.x - end.x);
        // create material
        var material = new standardMaterial_1.StandardMaterial("player_spell");
        material.diffuseColor = this.colors[color][0];
        // create mesh
        var projectile = meshBuilder_1.MeshBuilder.CreateSphere("Projectile", { segments: 4, diameter: 0.4 }, this._scene);
        projectile.material = material;
        projectile.position = start.clone();
        projectile.rotation.y = Math.PI / 2 - angle;
        //////////////////////////////////////////////
        // create particule trail
        var particleSystemTrail = new particleSystem_1.ParticleSystem("particles", 200, this._scene);
        particleSystemTrail.particleTexture = this.particleTxt_01.clone();
        particleSystemTrail.emitter = projectile; // the starting location
        // Colors of all particles
        particleSystemTrail.color1 = math_color_1.Color4.FromColor3(this.colors[color][0]);
        particleSystemTrail.color2 = math_color_1.Color4.FromColor3(this.colors[color][1]);
        particleSystemTrail.colorDead = new math_color_1.Color4(0, 0, 0, 0.0);
        // Size of each particle (random between...
        particleSystemTrail.minSize = 0.4;
        particleSystemTrail.maxSize = 0.6;
        // Life time of each particle (random between...
        particleSystemTrail.minLifeTime = 0.05;
        particleSystemTrail.maxLifeTime = 0.1;
        // Emission rate
        particleSystemTrail.emitRate = 1000;
        particleSystemTrail.createPointEmitter(new math_vector_1.Vector3(-1, 0, -0), new math_vector_1.Vector3(0, 0, 0));
        // Speed
        particleSystemTrail.minEmitPower = 1;
        particleSystemTrail.maxEmitPower = 2;
        //particleSystem.updateSpeed = 0.01;
        // Start the particle system
        particleSystemTrail.start();
        // create particule spray
        var particleSystem = new particleSystem_1.ParticleSystem("particles", 200, this._scene);
        particleSystem.particleTexture = this.particleTxt_01.clone();
        particleSystem.emitter = projectile; // the starting location
        // Colors of all particles
        particleSystem.color1 = math_color_1.Color4.FromColor3(this.colors[color][0]);
        particleSystem.color2 = math_color_1.Color4.FromColor3(this.colors[color][1]);
        particleSystem.colorDead = new math_color_1.Color4(0, 0, 0, 0.0);
        // Size of each particle (random between...
        particleSystem.minSize = 0.1;
        particleSystem.maxSize = 0.3;
        // Life time of each particle (random between...
        particleSystem.minLifeTime = 0.1;
        particleSystem.maxLifeTime = 0.3;
        // Emission rate
        particleSystem.emitRate = 50;
        particleSystem.createSphereEmitter(1);
        // Start the particle system
        particleSystem.start();
        //////////////////////////////////////////////
        //
        this.projectiles.set((0, nanoid_1.nanoid)(), {
            color: color,
            ability: ability,
            source: source,
            target: target,
            projectile: projectile,
            particleSystem: particleSystem,
            particleSystemTrail: particleSystemTrail,
        });
    }
}
exports.EntityActions = EntityActions;
