"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExperienceBar = void 0;
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Theme_1 = require("./Theme");
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const Leveling_1 = require("../../../shared/Class/Leveling");
class ExperienceBar {
    constructor(_UI, _currentPlayer) {
        this._UI = _UI;
        this._playerUI = _UI._playerUI;
        this._currentPlayer = _currentPlayer;
        this._scene = _UI._scene;
        this._createUI();
        // some ui must be refreshed as things change
        let entity = this._currentPlayer.entity;
        if (entity && entity.player_data) {
            entity.player_data.onChange((item, sessionId) => {
                this.update();
            });
        }
    }
    _createUI() {
        /////////////////////////////////////
        //////////////////// mana bar
        const experienceBar = new rectangle_1.Rectangle("experienceBar");
        experienceBar.top = "2px;";
        experienceBar.left = "0px";
        experienceBar.width = 1;
        experienceBar.height = "20px";
        experienceBar.thickness = 2;
        experienceBar.color = "rgba(0,0,0,1)";
        experienceBar.background = (0, Theme_1.getBg)();
        experienceBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        experienceBar.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        this._playerUI.addControl(experienceBar);
        const experienceBarInside = new rectangle_1.Rectangle("experienceBarInside");
        experienceBarInside.top = "0px;";
        experienceBarInside.left = "0px;";
        experienceBarInside.width = "400px;";
        experienceBarInside.thickness = 0;
        experienceBarInside.height = "20px";
        experienceBarInside.background = "violet";
        experienceBarInside.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        experienceBarInside.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        experienceBar.addControl(experienceBarInside);
        this.experienceBarUI = experienceBarInside;
        const experienceBarTextRight = new textBlock_1.TextBlock("experienceBarTextRight");
        experienceBarTextRight.text = "0";
        experienceBarTextRight.color = "#FFF";
        experienceBarTextRight.top = "0px";
        experienceBarTextRight.left = "-5px";
        experienceBarTextRight.fontSize = "11px;";
        experienceBarTextRight.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        experienceBarTextRight.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        experienceBarTextRight.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        experienceBarTextRight.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        experienceBar.addControl(experienceBarTextRight);
        this.experienceBarTextRight = experienceBarTextRight;
        const experienceBarTextLeft = new textBlock_1.TextBlock("experienceBarTextLeft");
        experienceBarTextLeft.text = "0";
        experienceBarTextLeft.color = "#FFF";
        experienceBarTextLeft.top = "1px";
        experienceBarTextLeft.left = "5px";
        experienceBarTextLeft.fontSize = "11px;";
        experienceBarTextLeft.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        experienceBarTextLeft.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        experienceBarTextLeft.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        experienceBarTextLeft.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        experienceBar.addControl(experienceBarTextLeft);
        this.experienceBarTextLeft = experienceBarTextLeft;
        // bars
        for (let i = 0; i <= 9; i++) {
            if (i !== 0) {
                let expBar = new rectangle_1.Rectangle("expBar" + i);
                expBar.top = 0;
                expBar.left = 0.1 * i * 100 + "%";
                expBar.top = "6px";
                expBar.width = "1px";
                expBar.height = "22px";
                expBar.thickness = 0;
                expBar.background = "rgba(255,255,255,.4)";
                expBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
                expBar.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                experienceBar.addControl(expBar);
            }
        }
    }
    update() {
        if (this._currentPlayer) {
            let player_experience = this._currentPlayer.player_data.experience;
            let progress = Leveling_1.Leveling.getLevelProgress(player_experience);
            this.experienceBarUI.width = progress / 100;
            this.experienceBarTextRight.text = progress + "%";
            this.experienceBarTextLeft.text =
                player_experience.toLocaleString() + " / " + Leveling_1.Leveling.getTotalLevelXp(this._currentPlayer.level).toLocaleString() + " EXP";
        }
    }
}
exports.ExperienceBar = ExperienceBar;
