"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Dialog = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const Panel_1 = require("./Panel");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const button_1 = require("@babylonjs/gui/2D/controls/button");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const TrainerDialog_1 = require("./Dialog/TrainerDialog");
const VendorDialog_1 = require("./Dialog/VendorDialog");
const QuestDialog_1 = require("./Dialog/QuestDialog");
class Panel_Dialog extends Panel_1.Panel {
    constructor(_UI, _currentPlayer, options) {
        super(_UI, _currentPlayer, options);
        this.currentDialogStep = -1;
        this.createContent();
    }
    open(entity) {
        super.open();
        if (entity) {
            this.currentEntity = entity;
            this.currentDialog = entity.spawnInfo.interactable.data;
            this.currentDialogStep = 0;
            this._panelTitle.text = entity.name;
            this.nextStep(this.currentDialogStep);
        }
    }
    clear() {
        this._panelContent.getDescendants().forEach((el) => {
            el.dispose();
        });
        this.createContent();
    }
    showTrainer(trainer) {
        this.clear();
        this.trainer = new TrainerDialog_1.TrainerDialog(this, trainer);
    }
    showVendor(vendor) {
        this.clear();
        this.vendor = new VendorDialog_1.VendorDialog(this, vendor);
    }
    showQuest(quest_id) {
        this.clear();
        this.quest = new QuestDialog_1.QuestDialog(this, quest_id);
    }
    replaceKeywords(text) {
        text = text.replace("@NpcName", this.currentEntity.name);
        text = text.replace("@LocationName", this._game.currentLocation.title);
        text = text.replace("@PlayerName", this._currentPlayer.name);
        return text;
    }
    nextStep(step) {
        var _a;
        if (!this.currentDialog[step]) {
            this.close();
        }
        // clear dialog
        this.clear();
        // get vars
        let currentDialog = this.currentDialog[step];
        // create main description textblock
        let dialogTextBlock = new textBlock_1.TextBlock("dialogText");
        dialogTextBlock.text = "";
        dialogTextBlock.textHorizontalAlignment = 0;
        dialogTextBlock.fontSize = "14px";
        dialogTextBlock.color = "white";
        dialogTextBlock.textWrapping = 1 /* TextWrapping.WordWrap */;
        dialogTextBlock.resizeToFit = true;
        dialogTextBlock.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        dialogTextBlock.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        this.dialogStackPanel.addControl(dialogTextBlock);
        // if dialog is type "text"
        if (currentDialog.type === "text") {
            let dialogText = currentDialog.text;
            dialogText = this.replaceKeywords(dialogText);
            dialogTextBlock.text = dialogText;
        }
        // create any quest buttons
        if (currentDialog.quests) {
            let q = 1;
            currentDialog.quests.forEach((btn) => {
                var _a;
                let playerQuest = (_a = this._currentPlayer.player_data.quests[btn.key]) !== null && _a !== void 0 ? _a : false;
                if (playerQuest && playerQuest.status === 1)
                    return false;
                let quest = this._game.getGameData("quest", btn.key);
                let color = playerQuest && playerQuest.status === 1 ? "gray" : "orange";
                const createBtn = button_1.Button.CreateSimpleButton("questBtn-" + q, "! " + quest.title);
                createBtn.left = "0px;";
                createBtn.top = "0px";
                createBtn.width = 1;
                createBtn.height = "24px";
                createBtn.background = color;
                createBtn.color = "white";
                createBtn.thickness = 0;
                this.dialogStackPanel.addControl(createBtn);
                createBtn.onPointerDownObservable.add(() => {
                    this.showQuest(btn.key);
                });
                q++;
            });
        }
        // create any trainer buttons
        if (currentDialog.trainer) {
            const createBtn = button_1.Button.CreateSimpleButton("gotoVendor", "Can you train me?");
            createBtn.left = "0px;";
            createBtn.top = "0px";
            createBtn.width = 1;
            createBtn.height = "24px";
            createBtn.background = "black";
            createBtn.color = "white";
            createBtn.thickness = 0;
            this.dialogStackPanel.addControl(createBtn);
            createBtn.onPointerDownObservable.add(() => {
                this.showTrainer(currentDialog.trainer);
            });
        }
        // create any vendor buttons
        if (currentDialog.vendor) {
            const createBtn = button_1.Button.CreateSimpleButton("gotoVendor", "Can I see your wares?");
            createBtn.left = "0px;";
            createBtn.top = "0px";
            createBtn.width = 1;
            createBtn.height = "24px";
            createBtn.background = "black";
            createBtn.color = "white";
            createBtn.thickness = 0;
            this.dialogStackPanel.addControl(createBtn);
            createBtn.onPointerDownObservable.add(() => {
                this.showVendor(currentDialog.vendor);
            });
        }
        // create any other buttons
        if (currentDialog.buttons) {
            let i = 1;
            currentDialog.buttons.forEach((btn) => {
                let label = btn.label;
                if (btn.isQuest) {
                    label = label + " (QUEST)";
                }
                const createBtn = button_1.Button.CreateSimpleButton("characterBtn-" + i, label);
                createBtn.left = "0px;";
                createBtn.top = "0px";
                createBtn.width = 1;
                createBtn.height = "24px";
                createBtn.background = "black";
                createBtn.color = "white";
                createBtn.thickness = 0;
                this.dialogStackPanel.addControl(createBtn);
                createBtn.onPointerDownObservable.add(() => {
                    this.nextStep(btn.goToDialog);
                });
                i++;
            });
        }
        // if last dialog in array, show close button
        if (currentDialog.isEndOfDialog) {
            let buttonName = (_a = currentDialog.buttonName) !== null && _a !== void 0 ? _a : "Bye";
            const createBtn = button_1.Button.CreateSimpleButton("characterBtn", buttonName);
            createBtn.left = "0px;";
            createBtn.top = "0px";
            createBtn.width = 1;
            createBtn.height = "24px";
            createBtn.background = "black";
            createBtn.color = "white";
            createBtn.thickness = 0;
            this.dialogStackPanel.addControl(createBtn);
            createBtn.onPointerDownObservable.add(() => {
                this.close();
            });
            // if event
            if (currentDialog.triggeredByClosing) {
                this.processEvent(currentDialog.triggeredByClosing);
            }
        }
    }
    processEvent(event) {
        if (event.type === "cast_ability") {
            this._currentPlayer.actionsController.process(this._currentPlayer, {
                key: event.ability,
                fromId: this.currentEntity.sessionId,
                fromPos: this.currentEntity.getPosition(),
                targetId: this._currentPlayer.sessionId,
                targetPos: this._currentPlayer.getPosition(),
            }, this._game.getGameData("ability", event.ability));
        }
    }
    // create panel
    createContent() {
        return __awaiter(this, void 0, void 0, function* () {
            let panel = this._panelContent;
            // dialog panel
            let dialogPanel = new rectangle_1.Rectangle("dialogPanel");
            dialogPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            dialogPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            dialogPanel.top = "0px";
            dialogPanel.left = "0px;";
            dialogPanel.width = 1;
            dialogPanel.height = 0.85;
            dialogPanel.thickness = 0;
            dialogPanel.setPadding(5, 5, 5, 5);
            dialogPanel.adaptHeightToChildren = true;
            dialogPanel.background = "rgba(255,255,255,.1)";
            panel.addControl(dialogPanel);
            // add scrollable container
            const chatStackPanel = new stackPanel_1.StackPanel("chatStackPanel");
            chatStackPanel.width = "100%";
            chatStackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            chatStackPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            chatStackPanel.paddingTop = "5px;";
            chatStackPanel.spacing = 5;
            dialogPanel.addControl(chatStackPanel);
            this.dialogStackPanel = chatStackPanel;
        });
    }
}
exports.Panel_Dialog = Panel_Dialog;
