"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var State;
(function (State) {
    State[State["NULL"] = 0] = "NULL";
    State[State["LOGIN"] = 1] = "LOGIN";
    State[State["CHARACTER_SELECTION"] = 2] = "CHARACTER_SELECTION";
    State[State["CHARACTER_EDITOR"] = 3] = "CHARACTER_EDITOR";
    State[State["DEBUG_SCENE"] = 4] = "DEBUG_SCENE";
    State[State["REGISTER"] = 5] = "REGISTER";
    State[State["GAME"] = 6] = "GAME";
    State[State["ANIMATION"] = 7] = "ANIMATION";
    State[State["LOADING"] = 8] = "LOADING";
})(State || (State = {}));
exports.default = State;
