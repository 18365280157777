"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Character = void 0;
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const Panel_1 = require("./Panel");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Theme_1 = require("../Theme");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const types_1 = require("../../../../shared/types");
const Rarity_1 = require("../../../../shared/Class/Rarity");
class Panel_Character extends Panel_1.Panel {
    constructor(_UI, _currentPlayer, options) {
        super(_UI, _currentPlayer, options);
        //
        this.attributes = {
            strength: {
                name: "Strength",
                button: true,
            },
            endurance: {
                name: "Endurance",
                button: true,
            },
            agility: {
                name: "Agility",
                button: true,
            },
            intelligence: {
                name: "Intelligence",
                button: true,
            },
            wisdom: {
                name: "Wisdom",
                button: true,
            },
            ac: {
                name: "AC",
                button: false,
            },
            points: {
                name: "Available Points",
            },
        };
        //
        this.stats = {
            name: {
                label: "Name",
                value: this._currentPlayer.name,
            },
            sessionId: {
                label: "ID",
                value: this._currentPlayer.sessionId,
            },
            level: {
                label: "Level",
                value: this._currentPlayer.level,
            },
            race: {
                label: "Race",
                value: this._currentPlayer.race,
            },
            health: {
                label: "Health",
                value: this._currentPlayer.health + "/" + this._currentPlayer.maxHealth,
            },
            mana: {
                label: "Mana",
                value: this._currentPlayer.mana + "/" + this._currentPlayer.maxMana,
            },
        };
        this.slots = [
            "HEAD",
            "AMULET",
            "CHEST", "PANTS", "SHOES", "WEAPON", "OFF_HAND", "RING_1", "RING_2", "BACK",
        ];
        // create UI
        this.createPanels();
        this.createContent();
        // dynamic events
        let entity = this._currentPlayer.entity;
        if (entity) {
            entity.player_data.onChange((item, sessionId) => {
                this.leftPanelContent(this.leftPanel);
                this.rightPanelContent(this.rightPanel);
            });
            entity.equipment.onAdd((item, sessionId) => {
                this.slotPanelContentRefresh("ADD", this.slotPanel, item);
            });
            entity.equipment.onRemove((item, sessionId) => {
                this.slotPanelContentRefresh("REMOVE", this.slotPanel, item);
            });
        }
    }
    // open panel
    open() {
        super.open();
    }
    // create content
    createContent() {
        this.leftPanelContent(this.leftPanel);
        this.rightPanelContent(this.rightPanel);
        this.slotPanelContent(this.slotPanel);
    }
    // create panel
    createPanels() {
        let panel = this._panelContent;
        // left panel
        let leftPanel = new rectangle_1.Rectangle("leftPanel");
        leftPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        leftPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        leftPanel.top = "0px";
        leftPanel.left = "0px;";
        leftPanel.width = 0.485;
        leftPanel.height = 0.8;
        leftPanel.thickness = 0;
        leftPanel.paddingLeft = "0px;";
        leftPanel.paddingBottom = "5px;";
        panel.addControl(leftPanel);
        this.leftPanel = leftPanel;
        // right panel
        let rightPanel = new rectangle_1.Rectangle("rightPanel");
        rightPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        rightPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        rightPanel.top = "0px";
        rightPanel.left = "0px";
        rightPanel.width = 0.485;
        rightPanel.height = 0.8;
        rightPanel.thickness = 0;
        rightPanel.paddingLeft = "0px;";
        rightPanel.paddingBottom = "5px;";
        panel.addControl(rightPanel);
        this.rightPanel = rightPanel;
        // bottom panel
        let slotPanel = new rectangle_1.Rectangle("slotPanel");
        slotPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        slotPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        slotPanel.top = "0px";
        slotPanel.left = "0px";
        slotPanel.width = 1;
        slotPanel.adaptHeightToChildren = true;
        slotPanel.thickness = 0;
        slotPanel.paddingLeft = "7px;";
        slotPanel.paddingRight = "7px;";
        slotPanel.paddingBottom = "7px;";
        panel.addControl(slotPanel);
        this.slotPanel = slotPanel;
    }
    leftPanelContent(panel) {
        // if already exists
        panel.children.forEach((el) => {
            el.dispose();
        });
        // panel title
        const stackPanel = new stackPanel_1.StackPanel("stackPanel");
        stackPanel.width = 1;
        stackPanel.adaptHeightToChildren = true;
        stackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        stackPanel.setPaddingInPixels(5, 5, 5, 5);
        panel.addControl(stackPanel);
        for (let key in this.stats) {
            // get ability details
            let line = this.stats[key];
            let panelRectangle = new rectangle_1.Rectangle("cont" + key);
            panelRectangle.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            panelRectangle.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            panelRectangle.top = "0px";
            panelRectangle.left = "0px;";
            panelRectangle.width = 1;
            panelRectangle.height = "30px";
            panelRectangle.background = "#CCC";
            panelRectangle.thickness = 1;
            panelRectangle.paddingLeft = "0px;";
            panelRectangle.paddingBottom = "5px;";
            (0, Theme_1.applyTheme)(panelRectangle);
            stackPanel.addControl(panelRectangle);
            const tooltipName = new textBlock_1.TextBlock("name" + key);
            tooltipName.color = "#FFF";
            tooltipName.top = "0px";
            tooltipName.left = "5px";
            tooltipName.fontSize = "14px;";
            tooltipName.resizeToFit = true;
            tooltipName.text = line.label;
            tooltipName.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            tooltipName.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            tooltipName.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            tooltipName.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            panelRectangle.addControl(tooltipName);
            const valueText = new textBlock_1.TextBlock("valueText" + key);
            valueText.color = "#FFF";
            valueText.top = "0px";
            valueText.left = "-5px";
            valueText.fontSize = "14px;";
            valueText.resizeToFit = true;
            valueText.text = line.value;
            valueText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            valueText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            valueText.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            valueText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            panelRectangle.addControl(valueText);
        }
    }
    rightPanelContent(panel) {
        var _a;
        // if already exists
        panel.children.forEach((el) => {
            el.dispose();
        });
        // panel title
        const stackPanel = new stackPanel_1.StackPanel("stackPanel");
        stackPanel.width = 1;
        stackPanel.adaptHeightToChildren = true;
        stackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        stackPanel.setPaddingInPixels(5, 5, 5, 5);
        panel.addControl(stackPanel);
        for (let key in this.attributes) {
            // get ability details
            let line = this.attributes[key];
            let panelRectangle = new rectangle_1.Rectangle("cont" + key);
            panelRectangle.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            panelRectangle.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            panelRectangle.top = "0px";
            panelRectangle.left = "0px;";
            panelRectangle.width = 1;
            panelRectangle.height = "30px";
            panelRectangle.background = "#CCC";
            panelRectangle.thickness = 1;
            panelRectangle.paddingLeft = "0px;";
            panelRectangle.paddingBottom = "5px;";
            (0, Theme_1.applyTheme)(panelRectangle);
            stackPanel.addControl(panelRectangle);
            const tooltipName = new textBlock_1.TextBlock("name" + key);
            tooltipName.color = "#FFF";
            tooltipName.top = "0px";
            tooltipName.left = "5px";
            tooltipName.fontSize = "14px;";
            tooltipName.resizeToFit = true;
            tooltipName.text = line.name;
            tooltipName.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            tooltipName.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            tooltipName.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            tooltipName.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            panelRectangle.addControl(tooltipName);
            const valueText = new textBlock_1.TextBlock("valueText" + key);
            valueText.color = "#FFF";
            valueText.top = "0px";
            valueText.left = "-5px";
            valueText.fontSize = "14px;";
            valueText.resizeToFit = true;
            valueText.text = (_a = this._currentPlayer.player_data[key]) !== null && _a !== void 0 ? _a : "ERROR";
            valueText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            valueText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            valueText.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            valueText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            panelRectangle.addControl(valueText);
            //
            if (line.button && this._currentPlayer.player_data.points > 0) {
                let button = (0, Theme_1.createButton)("button", "+", "20px", "20px");
                button.background = "green";
                button.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
                button.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
                panelRectangle.addControl(button);
                button.onPointerDownObservable.add(() => {
                    this._game.sendMessage(types_1.ServerMsg.PLAYER_ADD_STAT_POINT, {
                        key: key,
                    });
                });
                // push the value text to the left
                valueText.left = "-30px";
            }
        }
    }
    slotPanelContent(panel) {
        let width = 484;
        let i = 0;
        this.slots.forEach((line) => {
            i++;
            // calculate responsive width and height
            let iconGutter = 4;
            let iconWidth = width / this.slots.length - iconGutter;
            let iconLeft = iconWidth + iconGutter;
            let leftMargin = i > 1 ? (i - 1) * iconLeft + "px" : "0px";
            let panelRectangle = new rectangle_1.Rectangle("slot_" + i);
            panelRectangle.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            panelRectangle.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            panelRectangle.top = "0px";
            panelRectangle.left = leftMargin;
            panelRectangle.width = iconWidth + "px";
            panelRectangle.height = iconWidth + "px";
            panelRectangle.thickness = 2;
            panelRectangle.color = "rgba(255,255,255, .3";
            panel.addControl(panelRectangle);
            var panelText = new textBlock_1.TextBlock("slot_text_" + i);
            panelText.text = line;
            panelText.fontSize = "10px";
            panelText.color = "rgba(255,255,255, .3)";
            panelText.fontWeight = "bold";
            panelText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            panelText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            panelRectangle.addControl(panelText);
            // add icon
            var img = new image_1.Image("slot_image_" + i, "./");
            img.stretch = image_1.Image.STRETCH_FILL;
            panelRectangle.addControl(img);
        });
    }
    slotPanelContentRefresh(type, panel, data) {
        // get information
        let slot_id = data.slot;
        let item_key = data.key;
        let slotPanel = panel.getChildByName("slot_" + slot_id);
        let slotImage = slotPanel.getChildByName("slot_image_" + slot_id);
        let item = this._game.getGameData("item", item_key);
        // make sure to remove any exisiting events
        slotImage.source = "";
        slotPanel.onPointerClickObservable.clear();
        slotPanel.onPointerEnterObservable.clear();
        slotPanel.onPointerOutObservable.clear();
        // equip item
        if (type === "ADD") {
            // color based on rarity
            let color = Rarity_1.Rarity.getColor(item);
            slotPanel.background = color.bg;
            slotPanel.color = color.color;
            slotPanel.thickness = 2;
            //
            var imageData = this._loadedAssets[item.icon];
            slotImage.source = imageData;
            slotPanel.onPointerClickObservable.add((e) => {
                if (e.buttonIndex === 2) {
                    this._game.sendMessage(types_1.ServerMsg.PLAYER_UNEQUIP_ITEM, {
                        key: item.key,
                    });
                }
            });
            slotPanel.onPointerEnterObservable.add((e) => {
                this._UI._Tooltip.refresh("item", item, slotPanel, "center", "top");
            });
            slotPanel.onPointerOutObservable.add((e) => {
                this._UI._Tooltip.close();
            });
        }
        if (type === "REMOVE") {
            slotPanel.background = "transparent";
            slotPanel.color = "rgba(255,255,255, .3";
        }
    }
}
exports.Panel_Character = Panel_Character;
