"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGraphHelper = exports.createConvexRegionHelper = void 0;
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
const mesh_1 = require("@babylonjs/core/Meshes/mesh");
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
const mesh_vertexData_1 = require("@babylonjs/core/Meshes/mesh.vertexData");
const transformNode_1 = require("@babylonjs/core/Meshes/transformNode");
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
function createConvexRegionHelper(navMesh, scene) {
    const regions = navMesh.regions;
    const customMesh = new mesh_1.Mesh("debug_navmesh", scene);
    customMesh.position = new math_vector_1.Vector3(0, 0.01, 0);
    const positions = [];
    const colors = [];
    for (let region of regions) {
        // one color for each convex region
        const color = math_color_1.Color3.Random();
        // count edges
        let edge = region.edge;
        const edges = [];
        do {
            edges.push(edge);
            edge = edge.next;
        } while (edge !== region.edge);
        // triangulate
        const triangleCount = edges.length - 2;
        for (let i = 1, l = triangleCount; i <= l; i++) {
            const v1 = edges[0].vertex;
            const v2 = edges[i + 0].vertex;
            const v3 = edges[i + 1].vertex;
            positions.push(v1.x, v1.y, v1.z);
            positions.push(v2.x, v2.y, v2.z);
            positions.push(v3.x, v3.y, v3.z);
            colors.push(color.r, color.g, color.b, 1);
            colors.push(color.r, color.g, color.b, 1);
            colors.push(color.r, color.g, color.b, 1);
        }
    }
    const indices = [];
    for (let i = 0; i < positions.length / 3; i++) {
        indices.push(i);
    }
    const normals = [];
    const vertexData = new mesh_vertexData_1.VertexData();
    mesh_vertexData_1.VertexData.ComputeNormals(positions, indices, normals);
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.normals = normals;
    vertexData.colors = colors;
    vertexData.applyToMesh(customMesh);
    var mat = new standardMaterial_1.StandardMaterial("navMeshDebugMaterial", scene);
    mat.backFaceCulling = false;
    mat.emissiveColor = new math_color_1.Color3(50, 50, 50);
    customMesh.material = mat;
    return customMesh;
}
exports.createConvexRegionHelper = createConvexRegionHelper;
function createGraphHelper(scene, graph, nodeSize = 1, nodeColor = "#4e84c4", edgeColor = "#ffffff") {
    const nodes = [];
    graph.getNodes(nodes);
    const parent = new transformNode_1.TransformNode("nodes-parent", scene);
    for (let node of nodes) {
        const nodeMaterial = new standardMaterial_1.StandardMaterial("node", scene);
        nodeMaterial.diffuseColor = math_color_1.Color3.FromHexString(nodeColor);
        const nodeMesh = meshBuilder_1.MeshBuilder.CreatePolyhedron("node", {
            type: 3, // Icosahedron
            size: nodeSize,
        }, scene);
        nodeMesh.parent = parent;
        nodeMesh.material = nodeMaterial;
        nodeMesh.position = new math_vector_1.Vector3(node.position.x, node.position.y, node.position.z);
        // edges
        const edges = [];
        const lines = [];
        for (let node of nodes) {
            graph.getEdgesOfNode(node.index, edges);
            const position = [];
            for (let edge of edges) {
                const fromNode = graph.getNode(edge.from);
                const toNode = graph.getNode(edge.to);
                position.push(new math_vector_1.Vector3(fromNode.position.x, fromNode.position.y, fromNode.position.z));
                position.push(new math_vector_1.Vector3(toNode.position.x, toNode.position.y, toNode.position.z));
            }
            lines.push(position);
        }
        const pathHelper = meshBuilder_1.MeshBuilder.CreateLineSystem("path-helper", {
            lines,
            updatable: false,
        }, scene);
        pathHelper.color = math_color_1.Color3.Green();
        pathHelper.parent = parent;
    }
    return parent;
}
exports.createGraphHelper = createGraphHelper;
