"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingBar = void 0;
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Theme_1 = require("./Theme");
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
class CastingBar {
    constructor(_UI, _currentPlayer) {
        this._UI = _UI;
        this._playerUI = _UI._playerUI;
        this._currentPlayer = _currentPlayer;
        this._createUI();
    }
    _createUI() {
        // add casting bar
        const castingBar = new rectangle_1.Rectangle("castingBar");
        castingBar.top = "-75px";
        castingBar.width = "150px";
        castingBar.height = "17px";
        castingBar.thickness = 1;
        castingBar.background = (0, Theme_1.getBg)();
        castingBar.isVisible = false;
        castingBar.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        castingBar.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        this._playerUI.addControl(castingBar);
        this._UICastingTimer = castingBar;
        const castingBarInside = new rectangle_1.Rectangle("castingBarInside");
        castingBarInside.top = "0px";
        castingBarInside.width = 1;
        castingBarInside.height = 1;
        castingBarInside.background = "rgba(255,255,255,0.5)";
        castingBarInside.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        castingBarInside.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        castingBar.addControl(castingBarInside);
        this._UICastingTimerInside = castingBarInside;
        const castingTimer = new textBlock_1.TextBlock("castingTimer");
        castingTimer.text = "0";
        castingTimer.color = "#FFF";
        castingTimer.top = 0;
        castingTimer.left = 0;
        castingTimer.fontSize = "11px;";
        castingTimer.color = "black;";
        castingTimer.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_CENTER;
        castingTimer.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        castingTimer.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        castingTimer.horizontalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        castingBar.addControl(castingTimer);
        this._UICastingTimerText = castingTimer;
    }
    update(text, width) {
        this._UICastingTimerText.text = text;
        this._UICastingTimerInside.width = width;
    }
    open() {
        this._UICastingTimer.isVisible = true;
        this._UICastingTimerText.text = "Start Casting";
    }
    close() {
        this._UICastingTimer.isVisible = false;
        this._UICastingTimerText.text = "";
    }
}
exports.CastingBar = CastingBar;
