"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cursor = void 0;
class Cursor {
    constructor(ui) {
        this._ui = ui;
        this.cursors = {
            buy: "./images/cursor/buy.png",
            sell: "./images/cursor/sell.png",
            talk: "./images/cursor/talk.png",
        };
    }
    activate(type) {
        document.documentElement.style.cursor = this.get(type);
    }
    get(type) {
        if (type === "hover") {
            return "url(./images/cursor/hand_hover.png), auto";
        }
        if (type === "buy") {
            return "url(./images/cursor/buy.png), auto";
        }
        if (type === "sell") {
            return "url(./images/cursor/sell.png), auto";
        }
        return "url(./images/cursor/hand.png), auto";
    }
}
exports.Cursor = Cursor;
