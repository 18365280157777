"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorDialog = void 0;
const control_1 = require("@babylonjs/gui/2D/controls/control");
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const scrollViewer_1 = require("@babylonjs/gui/2D/controls/scrollViewers/scrollViewer");
const stackPanel_1 = require("@babylonjs/gui/2D/controls/stackPanel");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const types_1 = require("../../../../../shared/types");
const button_1 = require("@babylonjs/gui/2D/controls/button");
class VendorDialog {
    constructor(panel, vendor) {
        this.backgroundColor = "#292929";
        this.backgroundSelected = "green";
        this.panel = panel;
        this.currentDialog = vendor;
        this.refresh();
    }
    refresh() {
        this.panel._panelContent.getDescendants().forEach((el) => {
            el.dispose();
        });
        // create ui
        this.create();
    }
    create() {
        const createBtn = button_1.Button.CreateSimpleButton("characterBtn", "Back");
        createBtn.left = "0px;";
        createBtn.top = "0px";
        createBtn.width = 1;
        createBtn.height = "24px";
        createBtn.background = "black";
        createBtn.color = "white";
        createBtn.thickness = 0;
        createBtn.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        createBtn.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        this.panel._panelContent.addControl(createBtn);
        createBtn.onPointerDownObservable.add(() => {
            this.sellingModeOff();
            this.panel.nextStep(0);
        });
        // add scrollable container
        const scrollViewer = new scrollViewer_1.ScrollViewer("scrollViewer");
        scrollViewer.width = 1;
        scrollViewer.height = 0.53;
        scrollViewer.top = "24px;";
        scrollViewer.thickness = 0;
        scrollViewer.background = this.backgroundColor;
        scrollViewer.setPaddingInPixels(5, 5, 5, 5);
        scrollViewer.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        scrollViewer.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        this.panel._panelContent.addControl(scrollViewer);
        // add details scrollable container
        let ItemDetailsBloc = new rectangle_1.Rectangle("ItemDetailsBloc");
        ItemDetailsBloc.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        ItemDetailsBloc.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        ItemDetailsBloc.width = 1;
        ItemDetailsBloc.height = 0.4;
        ItemDetailsBloc.top = -0.1;
        ItemDetailsBloc.background = "black";
        ItemDetailsBloc.setPaddingInPixels(5, 5, 5, 5);
        ItemDetailsBloc.thickness = 0;
        this.panel._panelContent.addControl(ItemDetailsBloc);
        this.panelDetails = ItemDetailsBloc;
        // add detail window
        const stackPanel = new stackPanel_1.StackPanel("stackPanel");
        stackPanel.width = 1;
        stackPanel.height = 1;
        stackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        stackPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        stackPanel.spacing = 0;
        stackPanel.adaptHeightToChildren = true;
        scrollViewer.addControl(stackPanel);
        this.stackPanel = stackPanel;
        if (this.currentDialog.items.length > 0) {
            this.currentDialog.items.forEach((a) => {
                let item = this.panel._game.getGameData("item", a.key);
                let blocContainer = new rectangle_1.Rectangle("blocContainer");
                blocContainer.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
                blocContainer.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                blocContainer.top = "0px";
                blocContainer.left = "0px;";
                blocContainer.width = 1;
                blocContainer.height = "25px";
                blocContainer.background = this.backgroundColor;
                blocContainer.thickness = 0;
                blocContainer.metadata = {
                    item: item,
                };
                stackPanel.addControl(blocContainer);
                var blockTitle = new textBlock_1.TextBlock("blockTitle");
                blockTitle.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
                blockTitle.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                blockTitle.paddingLeft = "5px";
                blockTitle.text = item.title;
                blockTitle.fontSize = "14px";
                blockTitle.color = "white";
                blockTitle.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
                blockTitle.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
                blocContainer.addControl(blockTitle);
                // on hover tooltip
                blocContainer.onPointerClickObservable.add(() => {
                    this.sellingModeOff();
                    this.select(blocContainer, blockTitle);
                    this.createDetails(item);
                });
            });
            // select first available ability
            let firstElement = this.stackPanel.children[0];
            let item = this.panel._game.getGameData("item", this.currentDialog.items[0].key);
            this.select(this.stackPanel.children[0], firstElement.children[0]);
            this.createDetails(item);
        }
        else {
            // nothing available to learn, show empty message
            const tooltipName = new textBlock_1.TextBlock("emptyText");
            tooltipName.color = "#FFF";
            tooltipName.top = "5px";
            tooltipName.left = "5px";
            tooltipName.resizeToFit = true;
            tooltipName.text = "Sorry, you've already leant all I had to teach.";
            tooltipName.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            tooltipName.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
            tooltipName.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
            tooltipName.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
            tooltipName.textWrapping = 1 /* TextWrapping.WordWrap */;
            stackPanel.addControl(tooltipName);
        }
    }
    select(blocContainer, blockTitle) {
        // reset all
        this.stackPanel.children.forEach((el) => {
            el.background = this.backgroundColor;
        });
        // color selected line
        blocContainer.background = "green";
        blockTitle.color = "white";
    }
    sellingModeOn() {
        this.panel._game.sellingMode = true;
        this.panel._UI._Cursor.activate("sell");
        if (this.sellBtn.textBlock) {
            this.sellBtn.textBlock.text = "Sell (ON)";
        }
    }
    sellingModeOff() {
        this.panel._game.sellingMode = false;
        this.panel._UI._Cursor.activate();
        if (this.sellBtn.textBlock) {
            this.sellBtn.textBlock.text = "Sell";
        }
    }
    createDetails(item) {
        // clear previous ability
        this.panelDetails.getDescendants().forEach((el) => {
            el.dispose();
        });
        ////////////////////////////////////////////
        ////////////////////////////////////////////
        ////////////////////////////////////////////
        let totalQuantity = 1;
        let actionBloc = new rectangle_1.Rectangle("actionBloc" + item.key);
        actionBloc.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        actionBloc.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        actionBloc.top = "0px";
        actionBloc.left = "0px;";
        actionBloc.width = 1;
        actionBloc.height = "35px;";
        actionBloc.thickness = 0;
        this.panelDetails.addControl(actionBloc);
        const sellBtn = button_1.Button.CreateSimpleButton("sellBtn", "Sell");
        sellBtn.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        sellBtn.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        sellBtn.left = "0px;";
        sellBtn.top = "0px";
        sellBtn.width = "100px;";
        sellBtn.height = "24px";
        sellBtn.background = "orange";
        sellBtn.color = "white";
        sellBtn.thickness = 0;
        sellBtn.fontSize = "14px";
        actionBloc.addControl(sellBtn);
        this.sellBtn = sellBtn;
        sellBtn.onPointerClickObservable.add(() => {
            if (this.panel._game.sellingMode === false) {
                this.sellingModeOn();
            }
            else {
                this.sellingModeOff();
            }
        });
        const createBtn = button_1.Button.CreateSimpleButton("buyBtn", "Buy " + totalQuantity);
        createBtn.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        createBtn.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        createBtn.left = "-29px;";
        createBtn.top = "0px";
        createBtn.width = "60px;";
        createBtn.height = "24px";
        createBtn.background = "orange";
        createBtn.color = "white";
        createBtn.thickness = 0;
        createBtn.fontSize = "14px";
        createBtn.hoverCursor = this.panel._UI._Cursor.get("buy");
        actionBloc.addControl(createBtn);
        const plusBtn = button_1.Button.CreateSimpleButton("plusBtn", "+");
        plusBtn.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        plusBtn.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        plusBtn.left = "0px;";
        plusBtn.top = "0px";
        plusBtn.width = "24px;";
        plusBtn.height = "24px";
        plusBtn.background = "gray";
        plusBtn.color = "white";
        plusBtn.thickness = 0;
        actionBloc.addControl(plusBtn);
        plusBtn.onPointerClickObservable.add(() => {
            totalQuantity++;
            if (createBtn.textBlock) {
                createBtn.textBlock.text = "Buy " + totalQuantity;
            }
        });
        const minusBtn = button_1.Button.CreateSimpleButton("minusBtn", "-");
        minusBtn.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        minusBtn.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        minusBtn.left = "-95px;";
        minusBtn.top = "0px";
        minusBtn.width = "24px;";
        minusBtn.height = "24px";
        minusBtn.background = "gray";
        minusBtn.color = "white";
        minusBtn.thickness = 0;
        actionBloc.addControl(minusBtn);
        minusBtn.onPointerClickObservable.add(() => {
            if (totalQuantity > 1) {
                totalQuantity--;
                if (createBtn.textBlock) {
                    createBtn.textBlock.text = "Buy " + totalQuantity;
                }
            }
        });
        let clicked = false;
        let observable = createBtn.onPointerClickObservable.add(() => {
            this.sellingModeOff();
            if (clicked === false) {
                clicked = true;
                this.panel._game.sendMessage(types_1.ServerMsg.PLAYER_BUY_ITEM, {
                    key: item.key,
                    qty: totalQuantity,
                });
                if (createBtn.textBlock) {
                    createBtn.textBlock.text = "...";
                }
                // todo: we need some sort of callback here
                setTimeout(() => {
                    totalQuantity = 1;
                    clicked = false;
                    if (createBtn.textBlock) {
                        createBtn.textBlock.text = "Buy " + totalQuantity;
                    }
                }, 200);
            }
        });
        ////////////////////////////////////////////
        ////////////////////////////////////////////
        ////////////////////////////////////////////
        const scrollViewerDetails = new scrollViewer_1.ScrollViewer("scrollViewerDetails");
        scrollViewerDetails.width = 1;
        scrollViewerDetails.height = 1;
        scrollViewerDetails.top = "35px";
        scrollViewerDetails.thickness = 0;
        scrollViewerDetails.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        scrollViewerDetails.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_BOTTOM;
        this.panelDetails.addControl(scrollViewerDetails);
        const stackPanel = new stackPanel_1.StackPanel("stackPanel");
        stackPanel.width = 1;
        stackPanel.height = 1;
        stackPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        stackPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        stackPanel.spacing = 5;
        stackPanel.adaptHeightToChildren = true;
        scrollViewerDetails.addControl(stackPanel);
        let titleBloc = new rectangle_1.Rectangle("titleBloc" + item.key);
        titleBloc.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        titleBloc.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        titleBloc.top = "5px";
        titleBloc.left = "0px;";
        titleBloc.width = 1;
        titleBloc.height = "35px;";
        titleBloc.thickness = 0;
        stackPanel.addControl(titleBloc);
        // add icon + title
        let imageBLoc = new rectangle_1.Rectangle("imageBLoc" + item.key);
        imageBLoc.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        imageBLoc.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        imageBLoc.top = "0px";
        imageBLoc.left = "0px;";
        imageBLoc.width = "30px;";
        imageBLoc.height = "30px;";
        imageBLoc.thickness = 0;
        titleBloc.addControl(imageBLoc);
        var imageData = this.panel._loadedAssets[item.icon];
        var img = new image_1.Image("itemImage_" + item.key, imageData);
        img.stretch = image_1.Image.STRETCH_FILL;
        imageBLoc.addControl(img);
        // add title
        const tooltipName = new textBlock_1.TextBlock("abilityName" + item.key);
        tooltipName.width = 0.7;
        tooltipName.color = "#FFF";
        tooltipName.top = "0px";
        tooltipName.left = "40px";
        tooltipName.fontSize = "18px;";
        tooltipName.fontWeight = "bold;";
        tooltipName.resizeToFit = true;
        tooltipName.textWrapping = 1 /* TextWrapping.WordWrap */;
        tooltipName.text = item.title;
        tooltipName.lineSpacing = "-4px";
        tooltipName.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        tooltipName.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        tooltipName.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        tooltipName.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        titleBloc.addControl(tooltipName);
        // addd description
        const abilityDescr = new textBlock_1.TextBlock("abilityDescr" + item.key);
        abilityDescr.color = "rgba(255,255,255,.6)";
        abilityDescr.top = 0;
        abilityDescr.left = "0px";
        abilityDescr.fontSize = "12px;";
        abilityDescr.textWrapping = 1 /* TextWrapping.WordWrap */;
        abilityDescr.resizeToFit = true;
        abilityDescr.text = item.description;
        abilityDescr.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        abilityDescr.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        abilityDescr.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        abilityDescr.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        stackPanel.addControl(abilityDescr);
        // add requirements
        let requirements = "";
        if (item.value) {
            requirements += "Cost: " + item.value + "\n";
        }
        const requiredBloc = new textBlock_1.TextBlock("requiredBloc" + item.key);
        requiredBloc.color = "rgba(255,255,255,.6)";
        requiredBloc.top = 0;
        requiredBloc.left = "0px";
        requiredBloc.fontSize = "12px;";
        requiredBloc.color = "orange";
        requiredBloc.textWrapping = 1 /* TextWrapping.WordWrap */;
        requiredBloc.resizeToFit = true;
        requiredBloc.text = requirements;
        requiredBloc.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        requiredBloc.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        requiredBloc.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        requiredBloc.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_LEFT;
        stackPanel.addControl(requiredBloc);
    }
}
exports.VendorDialog = VendorDialog;
