"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMove = void 0;
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
const types_1 = require("../../../shared/types");
const yuka_min_1 = require("../../../shared/Libs/yuka-min");
class EntityMove {
    constructor(entity) {
        this.playerInputs = [];
        this.sequence = 0;
        this._node = entity;
        this._input = entity._input;
        this._game = entity._game;
        this._mesh = entity.mesh;
        this._navMesh = entity._navMesh;
        this.speed = entity.speed;
        this.isCurrentPlayer = entity.isCurrentPlayer;
    }
    getNextPosition() {
        return this.nextPosition;
    }
    getNextRotation() {
        return this.nextRotation;
    }
    setPositionAndRotation(entity) {
        this.nextPosition = new math_vector_1.Vector3(entity.x, entity.y, entity.z);
        this.nextRotation = new math_vector_1.Vector3(0, entity.rot, 0);
    }
    // server Reconciliation. Re-apply all the inputs not yet processed by the server
    reconcileMove(latestSequence) {
        // store latest sequence processed by server
        this.playerLatestSequence = latestSequence;
        // if nothing to apply, do nothin
        if (!this.playerInputs.length)
            return false;
        var j = 0;
        while (j < this.playerInputs.length) {
            var nextInput = this.playerInputs[j];
            if (nextInput.seq <= this.playerLatestSequence) {
                // Already processed. Its effect is already taken into account into the world update
                // we just got, so we can drop it.
                this.playerInputs.splice(j, 1);
            }
            else {
                // Not processed by the server yet. Re-apply it.
                this.move(nextInput);
                j++;
            }
        }
    }
    // prediction move
    predictionMove(latestInput) {
        // move player locally
        this.move(latestInput);
        // Save this input for later reconciliation.
        this.playerInputs.push(latestInput);
    }
    // update loop
    update(tween = 0.2) {
        // continuously lerp between current position and next position
        this._node.position = math_vector_1.Vector3.Lerp(this._node.position, this.nextPosition, tween);
        // continuously lerp between current rotation and next rotation
        const gap = Math.abs(this._node.rotation.y - this.nextRotation.y);
        if (gap > Math.PI) {
            this._node.rotation.y = this.nextRotation.y;
        }
        else {
            this._node.rotation = math_vector_1.Vector3.Lerp(this._node.rotation, this.nextRotation, 0.45);
        }
        // rotate camera to copy player rotation
        if (this._node.isCurrentPlayer) {
            this._node.cameraController._camRoot.rotation.y = -this._node.rotation.y + this._game.deltaCamY;
        }
    }
    move(input) {
        let speed = this.speed;
        // save current position
        let oldX = this.nextPosition.x;
        let oldY = this.nextPosition.y;
        let oldZ = this.nextPosition.z;
        // calculate new position
        let newX = oldX - input.h * speed;
        let newY = oldY;
        let newZ = oldZ - input.v * speed;
        const newRotY = Math.atan2(input.h, input.v);
        // check if destination is in navmesh
        let sourcePos = new yuka_min_1.Vector3(oldX, oldY, oldZ); // new pos
        let destinationPos = new yuka_min_1.Vector3(newX, newY, newZ); // new pos
        // get clamped position
        let clampedPosition = this._navMesh.clampMovementV2(sourcePos, destinationPos);
        // collision detected, return player old position
        this.nextPosition.x = clampedPosition.x;
        this.nextPosition.y = clampedPosition.y;
        this.nextPosition.z = clampedPosition.z;
        this.nextRotation.y = this.nextRotation.y + (newRotY - this.nextRotation.y);
        //
        let nextRegion = this._navMesh.getRegionForPoint(clampedPosition, 0.5);
        if (nextRegion && nextRegion.plane) {
            const distance = nextRegion.plane.distanceToPoint(clampedPosition);
            newY -= distance; // smooth transition
        }
    }
    //
    processMove() {
        let inputStrength = this._input.horizontal + this._input.vertical;
        // detect movement
        if (this._input.player_can_move && !this._node.blocked && inputStrength !== 0) {
            // increment seq
            this.sequence++;
            // prepare input to be sent
            let latestInput = {
                seq: this.sequence,
                h: this._input.horizontal,
                v: this._input.vertical,
            };
            // sent current input to server for processing
            this._game.sendMessage(types_1.ServerMsg.PLAYER_MOVE, latestInput);
            // do client side prediction
            this.predictionMove(latestInput);
        }
    }
}
exports.EntityMove = EntityMove;
