"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInterface = void 0;
const advancedDynamicTexture_1 = require("@babylonjs/gui/2D/advancedDynamicTexture");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const UI_1 = require("./UI");
class UserInterface {
    constructor(game, entities, currentPlayer) {
        // labels
        this.showingLabels = false;
        // set var we will be needing
        this._game = game;
        this._scene = game.scene;
        this._engine = game.engine;
        this._room = game.currentRoom;
        this._chatRoom = game.currentChat;
        this._entities = entities;
        this._currentPlayer = currentPlayer;
        this._loadedAssets = this._game._loadedAssets;
        // create ui
        const LABELS_ADT = advancedDynamicTexture_1.AdvancedDynamicTexture.CreateFullscreenUI("UI_Names", true, this._scene);
        this.LABELS_ADT = LABELS_ADT;
        // create ui
        const uiLayer = advancedDynamicTexture_1.AdvancedDynamicTexture.CreateFullscreenUI("UI_Player", true, this._scene);
        uiLayer.renderScale = 1;
        this.MAIN_ADT = uiLayer;
        //
        const uiLayerContainer = new rectangle_1.Rectangle("uiLayerContainer");
        uiLayerContainer.width = 1;
        uiLayerContainer.height = 1;
        uiLayerContainer.thickness = 0;
        uiLayerContainer.fontFamily = "Arial, sans-serif";
        uiLayerContainer.fontSize = "14px;";
        uiLayer.addControl(uiLayerContainer);
        this._playerUI = uiLayerContainer;
    }
    // set current player
    ////////////////////////////
    setCurrentPlayer(currentPlayer) {
        // set current player
        this._currentPlayer = currentPlayer;
        // cursor
        this._Cursor = new UI_1.Cursor(this);
        this._Watermark = new UI_1.Watermark(this);
        // create debug ui + events
        this._DebugBox = new UI_1.DebugBox(this._playerUI, this._engine, this._scene, this._room, this._currentPlayer, this._entities);
        // create main interface elements
        this._MainMenu = new UI_1.MainMenu(this, currentPlayer);
        this._CastingBar = new UI_1.CastingBar(this, currentPlayer);
        this._RessurectBox = new UI_1.RessurectBox(this, currentPlayer);
        this._ExperienceBar = new UI_1.ExperienceBar(this, currentPlayer);
        // create hotbar and events
        this._HotBar = new UI_1.HotBar(this, currentPlayer);
        // create chat ui + events
        this._ChatBox = new UI_1.ChatBox(this._playerUI, this._chatRoom, currentPlayer, this._entities, this._game);
        // create selected entity panel
        this._targetEntitySelectedBar = new UI_1.EntitySelectedBar(this, {
            panelName: "target",
            currentPlayer: false,
        });
        this._playerEntitySelectedBar = new UI_1.EntitySelectedBar(this, {
            panelName: "player",
            currentPlayer: currentPlayer,
        });
        this._playerEntitySelectedBar.setTarget(currentPlayer);
        // create panel
        this.panelInventory = new UI_1.Panel_Inventory(this, currentPlayer, {
            name: "Inventory",
            stayOpen: true,
            width: "246px;",
            height: "300px;",
            top: "-30px;",
            left: "-15px;",
            horizontal_position: control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT,
            vertical_position: control_1.Control.VERTICAL_ALIGNMENT_BOTTOM,
        });
        this._InventoryDropdown = new UI_1.InventoryDropdown(this);
        // create panel
        this.panelAbilities = new UI_1.Panel_Abilities(this, currentPlayer, {
            name: "Abilities",
            width: "500px;",
            height: "400px;",
            top: "-50px;",
            left: "0px;",
            horizontal_position: control_1.Control.HORIZONTAL_ALIGNMENT_CENTER,
            vertical_position: control_1.Control.VERTICAL_ALIGNMENT_CENTER,
        });
        // create panel
        this.panelCharacter = new UI_1.Panel_Character(this, currentPlayer, {
            name: "Character",
            width: "600px;",
            height: "320px;",
            top: "-50px;",
            left: "0px;",
            horizontal_position: control_1.Control.HORIZONTAL_ALIGNMENT_CENTER,
            vertical_position: control_1.Control.VERTICAL_ALIGNMENT_CENTER,
        });
        // create help panel
        this.panelHelp = new UI_1.Panel_Help(this, currentPlayer, {
            name: "Welcome to T5C",
            width: "500px;",
            height: "500px;",
            top: "-50px;",
            left: "0px;",
            horizontal_position: control_1.Control.HORIZONTAL_ALIGNMENT_CENTER,
            vertical_position: control_1.Control.VERTICAL_ALIGNMENT_CENTER,
        });
        // create dialog panel
        this.panelDialog = new UI_1.Panel_Dialog(this, currentPlayer, {
            name: "Dialog Panel",
            width: "350px;",
            height: "400px;",
            top: "-50px;",
            left: "0px;",
            horizontal_position: control_1.Control.HORIZONTAL_ALIGNMENT_CENTER,
            vertical_position: control_1.Control.VERTICAL_ALIGNMENT_CENTER,
        });
        // create quests panel
        this.panelQuests = new UI_1.Panel_Quests(this, currentPlayer, {
            name: "Active Quests",
            width: "300px;",
            height: "300px;",
            top: "-50px;",
            left: "0px;",
            horizontal_position: control_1.Control.HORIZONTAL_ALIGNMENT_CENTER,
            vertical_position: control_1.Control.VERTICAL_ALIGNMENT_CENTER,
        });
        // open inventory by default
        this.panelInventory.open();
        //this.panelHelp.open();
        // create tooltip
        this._Tooltip = new UI_1.Tooltip(this, currentPlayer);
        // initial resize event
        this.resize();
    }
    // update every server tick
    update() {
        if (this._Tooltip) {
            this._Tooltip.update();
        }
        //
        this.dragging();
    }
    // runs in the afterRender callback.
    // update every 1000ms
    slow_update() {
        if (this._targetEntitySelectedBar) {
            this._targetEntitySelectedBar.update();
        }
        if (this._playerEntitySelectedBar) {
            this._playerEntitySelectedBar.update();
        }
        if (this._DebugBox) {
            this._DebugBox.update();
        }
        if (this.panelInventory) {
            this.panelInventory.update();
        }
        if (this.panelAbilities) {
            this.panelAbilities.update();
        }
        if (this.panelCharacter) {
            this.panelCharacter.update();
        }
        if (this.panelHelp) {
            this.panelHelp.update();
        }
        if (this.panelDialog) {
            this.panelDialog.update();
        }
        if (this.panelQuests) {
            this.panelQuests.update();
        }
    }
    dragging() {
        if (this._isDragging) {
            var deltaX = this._scene.pointerX - this._pointerDownPosition.x;
            var deltaY = this._scene.pointerY - this._pointerDownPosition.y;
            this._isDragging.leftInPixels += deltaX;
            this._isDragging.topInPixels += deltaY;
            this._pointerDownPosition.x = this._scene.pointerX;
            this._pointerDownPosition.y = this._scene.pointerY;
            this._InventoryDropdown.refresh();
        }
    }
    startDragging(panel) {
        this._isDragging = panel;
        this._pointerDownPosition = { x: this._scene.pointerX, y: this._scene.pointerY };
    }
    stopDragging() {
        this._isDragging.isPointerBlocker = true;
        this._isDragging = null;
    }
    resize() {
        if (this._engine.getRenderWidth() < 1100) {
            if (this._ChatBox) {
                this._ChatBox.chatPanel.top = "-115px;";
            }
        }
        else {
            if (this._ChatBox) {
                this._ChatBox.chatPanel.top = "-30px;";
            }
        }
    }
}
exports.UserInterface = UserInterface;
