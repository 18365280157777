"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loading = void 0;
class Loading {
    constructor(loadingUIText) {
        this.loadingUIText = loadingUIText;
        this.loadingScreenDiv = window.document.getElementById("loadingScreen");
        this.loadingScreenTxt = window.document.getElementById("loadingText");
        this.loadingTextDetailsTxt = window.document.getElementById("loadingTextDetails");
    }
    displayLoadingUI() {
        console.log("displaying loading");
        this.loadingScreenDiv.style.display = "block";
        this.loadingScreenTxt.innerHTML = "Loading Assets...";
    }
    hideLoadingUI() {
        console.log("hiding loading");
        this.loadingScreenDiv.style.display = "none";
    }
}
exports.Loading = Loading;
