"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugBox = void 0;
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const control_1 = require("@babylonjs/gui/2D/controls/control");
const Utils_1 = require("../../../shared/Utils");
const Theme_1 = require("./Theme");
const types_1 = require("../../../shared/types");
const sceneInstrumentation_1 = require("@babylonjs/core/Instrumentation/sceneInstrumentation");
class DebugBox {
    constructor(_playerUI, _engine, _scene, _room, _currentPlayer, _entities) {
        this.ping = 0;
        this.currentDrawCallCounter = 0;
        this._playerUI = _playerUI;
        this._engine = _engine;
        this._scene = _scene;
        this._room = _room;
        this._currentPlayer = _currentPlayer;
        this._entities = _entities;
        this.instrumentation = new sceneInstrumentation_1.SceneInstrumentation(this._scene);
        this._createUI();
        // on pong
        this._room.onMessage(types_1.ServerMsg.PONG, (data) => {
            let dateNow = Date.now();
            this.ping = dateNow - data.date;
        });
    }
    _createUI() {
        const debugPanel = (0, Theme_1.generatePanel)("debugPanel", "160px;", "260px", "-100px", "-15px");
        debugPanel.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_CENTER;
        debugPanel.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        debugPanel.isVisible = false;
        this._playerUI.addControl(debugPanel);
        this._debugPanel = debugPanel;
        const debugText = new textBlock_1.TextBlock("debugText");
        debugText.color = "#FFF";
        debugText.top = "5px";
        debugText.left = "-5px";
        debugText.fontSize = "12px;";
        debugText.resizeToFit = true;
        debugText.text = "TEXT";
        debugText.textHorizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        debugText.textVerticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        debugText.verticalAlignment = control_1.Control.VERTICAL_ALIGNMENT_TOP;
        debugText.horizontalAlignment = control_1.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        debugPanel.addControl(debugText);
        this._debugTextUI = debugText;
        debugPanel.onPointerClickObservable.add(() => {
            navigator.clipboard.writeText(`new Vector3(${(0, Utils_1.roundTo)(this._currentPlayer.x, 2)}, ${(0, Utils_1.roundTo)(this._currentPlayer.y, 2)}, ${(0, Utils_1.roundTo)(this._currentPlayer.z, 2)})`);
        });
    }
    // debug panel refresh
    update() {
        // only update if visible
        if (this._debugPanel.isVisible === false) {
            return false;
        }
        let entityCount = this._entities.size;
        let count = 0;
        this._entities.forEach((element) => {
            if (element.mesh && element.mesh.isEnabled()) {
                count += 1;
            }
        });
        let locationText = "";
        locationText += "Total Nodes: " + entityCount + " \n";
        locationText += "Visible Nodes: " + count + " \n";
        locationText += "FPS: " + (0, Utils_1.roundTo)(this._engine.getFps(), 0) + " \n";
        locationText += "Draw Calls: " + this.instrumentation.drawCallsCounter.current + " \n";
        locationText += "Total Meshes: " + this._scene.meshes.length + " \n\n";
        locationText += "Ping: " + this.ping + "ms\n";
        locationText += "X: " + (0, Utils_1.roundTo)(this._currentPlayer.x, 2) + "\n";
        locationText += "y: " + (0, Utils_1.roundTo)(this._currentPlayer.y, 2) + "\n";
        locationText += "z: " + (0, Utils_1.roundTo)(this._currentPlayer.z, 2) + "\n";
        locationText += "Rot: " + (0, Utils_1.roundTo)(this._currentPlayer.rot, 2) + "\n";
        locationText += "Press N: Toggle Navmesh\n";
        locationText += "Press H: Toggle Map\n";
        locationText += "Press J: Remove Entities\n";
        this._debugTextUI.text = locationText;
    }
}
exports.DebugBox = DebugBox;
