"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Network = void 0;
// colyseus
const colyseus_js_1 = require("colyseus.js");
const Utils_1 = require("../Utils");
class Network {
    constructor(port) {
        // create colyseus client
        let url = "wss://" + window.location.hostname;
        if ((0, Utils_1.isLocal)()) {
            url = "ws://localhost:" + port;
        }
        this._client = new colyseus_js_1.Client(url);
    }
    joinRoom(roomId, token, character_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this._client.joinById(roomId, {
                token: token,
                character_id: character_id,
            });
        });
    }
    joinChatRoom(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this._client.joinOrCreate("chat_room", data);
        });
    }
    findCurrentRoom(currentRoomKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let rooms = yield this._client.getAvailableRooms("game_room");
                if (rooms.length > 0) {
                    rooms.forEach((room) => {
                        if (room.metadata.location === currentRoomKey) {
                            resolve(room);
                        }
                    });
                }
                resolve(false);
            }));
        });
    }
    joinOrCreateRoom(location, token, character_id) {
        return __awaiter(this, void 0, void 0, function* () {
            // find all exisiting rooms
            let rooms = yield this._client.getAvailableRooms("game_room");
            // rooms exists
            if (rooms.length > 0) {
                // do we already have a room for the specified location
                let roomIdFound = false;
                rooms.forEach((room) => {
                    if (room.metadata.location === location) {
                        roomIdFound = room.roomId;
                    }
                });
                // if so, let's join it
                if (roomIdFound !== false) {
                    return yield this.joinRoom(roomIdFound, token, character_id);
                }
            }
            // else create a new room for that location
            return yield this._client.create("game_room", {
                location: location,
                token: token,
                character_id: character_id,
            });
        });
    }
}
exports.Network = Network;
