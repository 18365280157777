"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
class Config {
    constructor() {
        // general settings
        this.title = "T5C";
        this.version = "Version 0.5.0";
        this.lang = "en";
        // server settings
        this.port = 3000;
        this.maxClients = 20; // set maximum clients per room
        this.updateRate = 100; // Set frequency the patched state should be sent to all clients, in milliseconds
        this.databaseUpdateRate = 10000; // the frequency at which server save data to the database, in milliseconds
        this.logLevel = "info";
        this.database = "sqllite"; // "mysql" or "sqllite"
        // game settings
        this.PLAYER_NAMEPLATE_TIMEOUT = 15000; // 15 seconds
        this.PLAYER_VIEW_DISTANCE = 30;
        this.PLAYER_LOSE_FOCUS_DISTANCE = 24;
        this.PLAYER_GRACE_PERIOD = 0;
        this.PLAYER_INTERACTABLE_DISTANCE = 5;
        this.PLAYER_INVENTORY_SPACE = 23;
        this.PLAYER_HOTBAR_SIZE = 9;
        this.COMBAT_SPEED = 1000;
        // enemies settings
        this.MONSTER_RESPAWN_RATE = 20000;
        this.MONSTER_CHASE_PERIOD = 4000;
        this.MONSTER_AGGRO_DISTANCE = 6;
        this.MONSTER_ATTACK_DISTANCE = 2;
        // ui theme settings
        this.UI_CENTER_PANEL_WIDTH = 0.6;
        this.UI_CENTER_PANEL_BG = "rgba(0,0,0,.5)";
        this.UI_SIDEBAR_WIDTH = "320px;";
        this.UI_PRIMARY_COLOR = "rgba(35, 168, 28, 0.8)";
        //
        this.SHADOW_ON = true;
        // default location
        this.initialLocation = "lh_town";
    }
}
exports.Config = Config;
